import React from 'react';
import '../css/manage-students.css';
import { addUser } from '../services/api';

const AddNewForm = ({ role, goBack }) => {
    const handleSubmit = async (e) => {
        e.preventDefault();

    const user = {
        firstName: e.target.firstName.value,
        lastName: e.target.lastName.value,
        email: e.target.email.value,
        password: e.target.password.value,
        role: role, // Assuming you're using role in the same format as in DB
        dob: e.target.dob.value,
        mobile: e.target.mobile.value
    };

    console.log("User:", user);

    try {
        const response = await addUser(user);
        alert(response.message); // Notify user of the response
        // go back to the manage page and refresh the list
        goBack();
    } catch (error) {
        console.error("Error adding user:", error);
        alert("Error adding user.");
    }
};

    return (
        <div className="students-page">
            <button onClick={goBack} className="back">Back to Manage {role}</button>
            <h2>Add New {role}</h2>
            <form id="addRoleForm" onSubmit={handleSubmit}>
                <label htmlFor="firstName">First Name:</label>
                <input type="text" id="firstName" required />

                <label htmlFor="lastName">Last Name:</label>
                <input type="text" id="lastName" required />

                <label htmlFor="email">Email:</label>
                <input type="text" id="email" required />
                <label htmlFor="dob">DOB</label>
                <input type="date"  required id="dob" name="dob" />
                {/* Add new field for mobile number */}
                <label htmlFor="mobile">Mobile Number</label>
                <input type="text"  required id="mobile" name="mobile"
                    pattern="[0-9]{10}" title="Please enter a valid 10 digit mobile number"

                />
                <label htmlFor="password">Password:</label> 
                <input type="password" id="password" required />

                <label htmlFor="confirmPassword">Confirm Password:</label>
                <input type="password" id="confirmPassword" required />

                <button type="submit" style={{backgroundColor: "#06c100"}}>
                    Add New {role}
                </button>
            </form>
        </div>
    );
};

export default AddNewForm;
