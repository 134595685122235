const BASE_URL = 'https://axt9215.uta.cloud/PHP-API/wdm-backend/src';
// const BASE_URL = 'http://localhost/wdm-backend/src';

export const fetchUserMessages = async (senderId, receiverId) => {
  try {
    const response = await fetch(`${BASE_URL}/chat/get_chat.php?senderId=${senderId}&receiverId=${receiverId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const sendMessage = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/chat/post_chat.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

// api.js

export const fetchSenderName = async (senderId) => {
  try {
    const response = await fetch(`${BASE_URL}/get_userdetails.php?senderId=${senderId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchUserListforChat = async (senderId, role) => {
  try {
    const response = await fetch(`${BASE_URL}/chat/get_userList.php?senderId=${senderId}&role=${role}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};
// Function to fetch a list of users
export const fetchUserList = async () => {
  try {
    const response = await fetch(`${BASE_URL}/get_users.php`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const authenticateUser = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/login.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    if (response.status === 'error') {
      throw new Error('Invalid username or password');
    } else {
      const activityData = {
        username: message.email,
        activity_type: `attempted to login at ${new Date().toLocaleString()}`,
      };
      await recordActivity(activityData);
      return response.json();

    }
  } catch (error) {
    throw error;
  }
};

export const fetchForumList = async (senderId, role) => {
  try {
    const response = await fetch(`${BASE_URL}/get_forum.php?senderId=${senderId}&role=${role}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const sendForumMessage = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/post_forum.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchForumMessages = async (senderId, courseId) => {
  try {
    const response = await fetch(`${BASE_URL}/get_forum_messages.php?senderId=${senderId}&courseId=${courseId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchQAPolicies = async () => {
  try {
    const response = await fetch(`${BASE_URL}/qapolicies/get_policies.php`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const createQAPolicy = async (policy) => {
  try {
    const response = await fetch(`${BASE_URL}/qapolicies/post_policies.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ policy }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const updateQAPolicy = async (id, data) => {
  try {
    const response = await fetch(`${BASE_URL}/qapolicies/put_policies.php`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, ...data }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteQAPolicy = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/qapolicies/delete_policies.php`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};
export const fetchRecommendations = async () => {
  try {
    const response = await fetch(`${BASE_URL}/QaRecommendations.php`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const createRecommendation = async (recommendationData) => {
  try {
    const response = await fetch(`${BASE_URL}/QaRecommendations.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(recommendationData),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const updateRecommendation = async (recommendationData) => {
  try {
    const response = await fetch(`${BASE_URL}/QaRecommendations.php`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(recommendationData),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteRecommendation = async (recommendationId) => {
  try {
    const response = await fetch(`${BASE_URL}/QaRecommendations.php`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: recommendationId }),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const handleContact= async (info) => {
  try {
    const response = await fetch(`${BASE_URL}/contact.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({info}),
    });
    return await response.text();
  } catch (error) {
    throw error;
  }
};

export const fetchAnalysis = async () => {
  try {
    const response = await fetch(`${BASE_URL}/studentAnalysis/get_analysis.php`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};
export const registerUser = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/register.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const activityData = {
      username: message.email,
      activity_type: `was registered at ${new Date().toLocaleString()}`,
    };
    await recordActivity(activityData);
    return response.json();
  } catch (error) {
    throw error;
  }
};
export const fetchfeedbackOnCourses = async () => {
  try {
    const response = await fetch(`${BASE_URL}/feedback/get_feedbackOnCourses.php`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchfeedbackOnProgram = async () => {
  try {
    const response = await fetch(`${BASE_URL}/feedback/get_feedbackOnProgram.php`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const studentProfile = async (userId) => {
  try {
    const response = await fetch(`${BASE_URL}/settings/get_studentProfile.php?userId=${userId}`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};





export const fetchUsersByRole = async (role) => {
  try {
    const response = await fetch(`${BASE_URL}/getUsersByRole.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ role: role }),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
}

export const addUser = async (user) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/addUser.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: user }),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
}


export const updateUser = async (user) => {
  const response = await fetch(`${BASE_URL}/auth/updateUser.php`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user })
  });

  return response.json();
}

export const deleteUser = async (id) => {
  const response = await fetch(`${BASE_URL}/auth/deleteUser.php`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: id })
  });
  return response.json();
}



export const fetchRecentActivities = async () => {
  const response = await fetch(`${BASE_URL}/activities/fetchActivities.php`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
}


export const recordActivity = async (data) => {
  try {
    await fetch(`${BASE_URL}/activities/insertActivity.php`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });
  } catch (error) {
    console.error('Error recording activity:', error);
  }
};

export const updateStudentProfile = async (user_id, password) => {
  try {
    debugger;
    const response = await fetch(`${BASE_URL}/settings/put_studentProfile.php`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id, password }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

// For registration
export const fetchCourses = async () => {
  try {
    const response = await fetch(`${BASE_URL}/get_courses.php`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchEnrolledCourses = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/get_enrolled_courses.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    };
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchAssignedCourses = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/get_assigned_courses.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    };
    return await response.json();
  } catch (error) {
    throw error;
  }
};

// add student to a course
export const addStudent = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/add_student.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text();
  } catch (error) {
    throw error;
  }
};

export const removeStudent = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/remove_student.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text();
  } catch (error) {
    throw error;
  }
};

// inserting a new course to the table
export const createCourse = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/post_course.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

// removing a course by ID
export const removeCourse = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/delete_course.php`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text();
  } catch (error) {
    throw error;
  }
};

// Update an existing course
export const editCourse = async (courseData) => {
  try {
    const response = await fetch(`${BASE_URL}/edit_course.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ courseData }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

// assign a instructor to a course
export const assignInstructor = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/assign_instructor.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text();
  } catch (error) {
    throw error;
  }
};

// remove an instructor from a course
export const removeInstructor = async (message) => {
  try {
    const response = await fetch(`${BASE_URL}/remove_instructor.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text();
  } catch (error) {
    throw error;
  }
}

export const fetchPOSObjective = async () => {
  try {
    const response = await fetch(`${BASE_URL}/get_POSMapping.php`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};


export const fetchInstructorCourses = async (Instructor_ID) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/get_instructor_courses.php?Instructor_ID=${Instructor_ID}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

// APIs for modules.jsx
export const uploadFiles = async (sectionName, courseId, files) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files[]', file);
  });
  formData.append('section', sectionName);
  formData.append('courseId', courseId);

  const response = await fetch(`${BASE_URL}/instructor/file_upload.php`, {
    method: 'POST',
    body: formData
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};



export const listFiles = async (courseId) => {
  const response = await fetch(`${BASE_URL}/instructor/list_files.php?courseId=${courseId}`);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};


export const downloadFile = async (fileId) => {
  window.location.href = `${BASE_URL}/instructor/download_file.php?fileId=${encodeURIComponent(fileId)}`;
};

export const viewFile = (fileId) => {
  const url = `${BASE_URL}/instructor/view_file.php?fileId=${encodeURIComponent(fileId)}`;
  window.open(url, '_blank');
};

export const deleteFiles = async (fileIds) => {
  const response = await fetch(`${BASE_URL}/instructor/delete_files.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ fileIds })
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};


export const updatePassword = async (email, tempPassword, newPassword) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/updatePassword.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, tempPassword, newPassword }),
    });

    const data = await response.json();
    return data;

  } catch (error) {
    throw error;
  }
};

export const fetchAnalyticsData = async (reportType) => {
  try {
    const response = await fetch(`${BASE_URL}/adminAnalytics.php`, {
      method: 'POST',  // change this to POST
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ reportType })
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching analytics data:', error);
    throw error;
  }
};

// get all the students' grades in a course
export const fetchStudentGrades = async (courseId) => {
  try {
    const response = await fetch(`${BASE_URL}/get_grades.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ courseId }),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
}

export const sendForgotPasswordEmail = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/forgotPassword.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();
    return data;

  } catch (error) {
    throw error;
  }
};

export const fetchPermissionsByRole = async () => {
  try {
    const response = await fetch(`${BASE_URL}/settings/get_permissions.php`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const updatePermissions = async (data) => {

  try {
    const response = await fetch(`${BASE_URL}/settings/put_permissions.php`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchEnrolledCoursesStudent = async (studentId) => {
  try {
    const response = await fetch(`${BASE_URL}/student/getEnrolledCourses.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ studentId }),
    });

    const data = await response.json();
    return data.courses; // Returning the courses array

  } catch (error) {
    throw error;
  }
};

export const getAllCoursesWithInstructors = async () => {
  try {
    const response = await fetch(`${BASE_URL}/student/getAllCoursesWithInstructors.php`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUpcomingExams = async () => {
  try {
    const response = await fetch(`${BASE_URL}/student/getUpcomingExams.php`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getStudentGrades = async (studentId) => {
  const response = await fetch(`${BASE_URL}/student/getStudentGrades.php?student_id=${studentId}`);
  const data = await response.json();
  return data;
};


// services/api.js
export const getStudentPerformance = async (studentId) => {
  const response = await fetch(`${BASE_URL}/student/getStudentPerformance.php?student_id=${studentId}`);
  const data = await response.json();
  return data;
};



export const updateSectionName = async (courseId, oldSectionName, newSectionName, fileIds) => {
  const response = await fetch(`${BASE_URL}/instructor/update_section.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      courseId,
      oldSection: oldSectionName,
      newSection: newSectionName,
      fileIds
    })
  });

  if (!response.ok) {
    throw new Error("Failed to update section name");
  }

  return await response.json();
}

export const fetchProgramObjectives = async (Course_ID) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/get_program_objectives.php?Course_ID=${Course_ID}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchCourseDescription = async (Course_ID) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/get_courseDescription.php?Course_ID=${Course_ID}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const saveCourseDescription = async (Course_ID, Course_Description) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/save_courseDescription.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Course_ID,
        Course_Description
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};


export const fetchSyllabus = async (Course_ID) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/get_syllabus.php?Course_ID=${Course_ID}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const saveSyllabus = async (Course_ID, Syllabus) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/save_syllabus.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Course_ID, Syllabus }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchExams = async (courseId) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/get_exams.php?Course_ID=${courseId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const createExam = async (examData) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/create_exam.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(examData),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchFeedbackAndGrades = async (courseId, examId) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/get_feedbackandgrades.php?Course_ID=${courseId}&Exam_ID=${examId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const saveGradesAndFeedback = async (studentId, examId, grade, feedback) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/save_grades_feedback.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Student_ID: studentId,
        Exam_ID: examId,
        Grade: grade,
        Instructor_Feedback: feedback
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};

export const getPerformanceMetrics = async (courseId, examId) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/get_reports.php?Course_ID=${courseId}&Exam_ID=${examId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};

export const getAnnouncements = async (courseId) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/get_announcments.php?Course_ID=${courseId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};

export const createAnnouncement = async (courseId, title, description) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/create_announcement.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Course_ID: courseId,
        Announcment_Title: title,
        Announcment_Description: description
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};

export const getCourseParticipants = async (courseId) => {
  const response = await fetch(`${BASE_URL}/instructor/get_courseParticipants.php?Course_ID=${courseId}`);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

export const getInstructorInfo = async (userId) => {
  const response = await fetch(`${BASE_URL}/instructor/get_instructor_info.php?User_ID=${userId}`);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};



// API service to update instructor info
export const updateInstructorInfo = async (userId, name, email, about) => {
  try {
    const response = await fetch(`${BASE_URL}/instructor/update_instructorInfo.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ User_ID: userId, Name: name, Email: email, About: about }),
    });

    const data = await response.json();
    return data;

  } catch (error) {
    throw error;
  }
};
