import jwt_decode from 'jwt-decode';

export function validateToken() {
  const token = localStorage.getItem('token');
  if (!token) {
    return false; // No token found
  }

  try {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      return false; // Token has expired
    }

    return true; // Token is valid
  } catch (error) {
    return false; // Invalid token
  }
};
