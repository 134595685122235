import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import { fetchExams, createExam } from '../../services/api';
import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import './exams.css';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const Exams = () => {
    document.title = "Exams";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const { course_id } = useParams();
    const [exams, setExams] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [examName, setExamName] = useState('');
    const [examDescription, setExamDescription] = useState('');
    const [examDate, setExamDate] = useState('');
    const [maxPoints, setMaxPoints] = useState('');
    const [banner, setBanner] = useState({ message: '', type: '' });

    const showBanner = (message, type) => {
        setBanner({ message, type });
        // Automatically hide the banner after 3 seconds
        setTimeout(() => setBanner({ message: '', type: '' }), 3000);
    };

    useEffect(() => {
        async function loadExams() {
            try {
                const fetchedExams = await fetchExams(course_id);
                setExams(fetchedExams);
            } catch (error) {
                console.error("Error fetching exams:", error);
            }
        }
        loadExams();
    }, [course_id]);

    const handleCreateExam = async () => {
        try {
            const examData = {
                Course_ID: course_id,
                Exam_Name: examName,
                Exam_Description: examDescription,
                Exam_Date: examDate,
                max_points: maxPoints,
            };
            const response = await createExam(examData);
            if (response.message) {
                setExams([...exams, examData]);
                setModalVisible(false);
                setExamName('');
                setExamDescription('');
                setExamDate('');
                setMaxPoints('');
                showBanner(response.message, 'success');
            }
        } catch (error) {
            showBanner(error || 'Error creating exam', 'failure');
            console.error("Error creating exam:", error);
        }
    };


    if (role === 'instructor') {
        return (
            <>
                <>
                    {banner.message && (
                        <div className={`banner banner-${banner.type}`}>
                            {banner.message}
                        </div>
                    )}
                    {/* Rest of your component */}
                </>
                <HeaderLoggedIn role={role} />
                <div className="exams-main-content">
                    <div className="exams-nav">
                        <Link to={`/instructor/coursehome/${course_id}`} >Home</Link>
                        <Link to={`/instructor/announcments/${course_id}`} >Announcements</Link>
                        <Link to={`/instructor/syllabus/${course_id}`}>Syllabus</Link>
                        <Link to={`/instructor/modules/${course_id}`}>Modules</Link>
                        <Link to={`/instructor/exams/${course_id}`} className="exams-selected">Exams</Link>
                        <Link to={`/instructor/grades/${course_id}`}>Grades</Link>
                        <Link to={`/instructor/people/${course_id}`}>People</Link>
                        <Link to={`/instructor/reports/${course_id}`}>Reports</Link>
                    </div>
                    <div className="exams-content">
                        <button className="create-exam" onClick={() => setModalVisible(true)}>
                            Create Exam
                        </button>
                        {exams.length > 0 && exams.map((exam, index) => (
                            <div key={index} className="exam">
                                <div className="exam-icon">
                                    📝
                                </div>
                                <div className="exam-details">
                                    <strong>{exam.Exam_Name}</strong>
                                    <div>Date: {exam.Exam_Date}</div>
                                    <div>Max Points: {exam.max_points}</div>
                                </div>
                            </div>
                        ))}

                        {modalVisible && (
                            <div className="modal">
                                <div className="modal-content">
                                    <span className="close-btn" onClick={() => setModalVisible(false)}>&times;</span>
                                    <h2>Create Exam</h2>
                                    <label>Exam Name</label>
                                    <input type="text" value={examName} onChange={e => setExamName(e.target.value)} />
                                    <label>Exam Description</label>
                                    <textarea value={examDescription} onChange={e => setExamDescription(e.target.value)}></textarea>
                                    <label>Due Date</label>
                                    <input type="date" value={examDate} onChange={e => setExamDate(e.target.value)} />
                                    <label>Max Points</label>
                                    <input type="number" value={maxPoints} onChange={e => setMaxPoints(e.target.value)} />
                                    <button onClick={handleCreateExam}>Save</button>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
                <Footer />
            </>
        );
    }
    else {
        return <Navigate to="/" replace={true} />
    };
};

export default Exams;
