import React from 'react';
import '../css/services.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { validateToken } from '../utils/authUtils';
import HeaderLoggedIn from '../components/headerLoggedIn';
import jwt_decode from 'jwt-decode';

const Services = () => {
    document.title = "Services";
    const token = localStorage.getItem('token');
    var role = '';
    if (token) {
        role = jwt_decode(token).role.toLowerCase();
    }
    return (
        <>
            {validateToken() ? <HeaderLoggedIn role={role}/> : <Header />}
            <div className="servicesContainer">
                <h1 className="servicesH1">Services and Roles</h1>
                <p className="servicesP">Welcome to the MSC Academic Program (Computer Science). Below are the key functionalities and roles in our program:</p>

                <div className="servicesSection">
                    <h2 className="servicesH2">Functionalities</h2>
                    <ul>
                        <li>Academic Program Overview</li>
                        <li>Course Listings</li>
                        <li>POS-Course Mapping</li>
                        <li>Exams and Assessments Management</li>
                        <li>User Account Management</li>
                        <li>Performance Reporting and Analytics</li>
                        <li>Feedback and Communication Systems</li>
                        <li>Administrative Panel</li>
                        <li>Responsive and Accessible Design</li>
                    </ul>
                </div>

                <div className="servicesSection">
                    <h2 className="servicesH2">Roles</h2>
                    <ul>
                        <li>Student: Access to academic resources, exam results, and communication features.</li>
                        <li>Instructor: Course content management, student performance monitoring, and direct communication with students.</li>
                        <li>Administrator: Overall control of website, user management, and program performance reporting.</li>
                        <li>Program Coordinator: Oversees the entire program, collaborates with instructors, and manages student concerns.</li>
                        <li>Quality Assurance Officer: Ensures program quality, monitors student performance, and maintains alignment with institutional goals.</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Services;
