import React, { useState, useEffect } from 'react';
import '../css/student_profile.css';
import HeaderLoggedIn from '../components/headerLoggedIn';
import Footer from '../components/footer';
import jwt_decode from 'jwt-decode';
import { studentProfile, updateStudentProfile } from '../services/api';
import { Navigate } from 'react-router-dom';

const StudentProfile = () => {
    document.title = "Student Profile";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const user_id = decodedToken.user_id;
    const [student, setStudent] = useState([]);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const edit_profile_status = decodedToken.permissions.edit_profile=== '0'? false : true;
    const view_profile_status = decodedToken.permissions.view_profile=== '0'? false : true;

    useEffect(() => {
        fetchStudentProfile();
    }, []);

    const fetchStudentProfile = async () => {
        studentProfile(user_id, password)
            .then((data) => {
                setStudent(data);
            })
            .catch((error) => console.error('Error fetching feedback on program: ', error));
    }
    const validatePassword = () => {
        if (!password) {
            setPasswordError('Password is required.');
            return false;
        } else if (password.length < 6) {
            setPasswordError('Password should be at least 6 characters.');
            return false;
        }
        setPasswordError('');
        return true;
    };

    const validateConfirmPassword = () => {
        if (!confirmPassword) {
            setConfirmPasswordError('Confirm Password is required.');
            return false;
        } else if (confirmPassword.length < 6) {
            setConfirmPasswordError('Confirm Password should be at least 6 characters.');
            return false;
        }
        setConfirmPasswordError('');
        return validatePasswordMatch();
    };
    const validatePasswordMatch = () => {
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            return false;
        }
        setConfirmPasswordError('');
        return true;
    }
    const handleUpdatePassword = () => {
        if (!edit_profile_status){
            alert('You dont have permission to edit password');
        }
        else if (!validatePassword()) {
            setConfirmPassword('');
            setPassword('');
        }
        else if (!validateConfirmPassword() || !validatePasswordMatch()) {
            
        }
        else if (password === student[0].Password) {
            alert('New password cannot be same as old password');
            setConfirmPassword('');
            setPassword('');
        }

        else if (confirmPasswordError === '' && passwordError === '') {
            setConfirmPasswordError('');
            setConfirmPasswordError('');
            setConfirmPassword('');
            setPassword('');
            updateStudentProfile(user_id, password)
                .then((response) => {
                    fetchStudentProfile();
                    alert(response.message);
                })
                .catch((error) => console.error('Error updating password: ', error));
        }
        else{}
    };
    if ( role === 'student' && view_profile_status) {
        return (
        <>
            <HeaderLoggedIn role={role} />
            <div className="content-containerStudent">
                {student.map((profile) => (


                    <div className="section-feedback" key={profile.User_ID}>
                        <h2>Profile Settings</h2>

                        <div className="profile-form" >
                            <label htmlFor="username">Username:</label>
                            <input type="text" id="username" name="username" readOnly='readonly' value={String(profile.First_Name + ' ' + profile.Last_Name)} /><br />

                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" readOnly='readonly' value={profile.Email_ID} /><br />

                            <label htmlFor="email">Date of Birth:</label>
                            <input type="email"  name="email" readOnly='readonly' value={profile.DOB} /><br />

                            <label htmlFor="email">Mobile Number:</label>
                            <input type="email"  name="email" readOnly='readonly' value={profile.mobile_number?profile.mobile_number:""} /><br />

                            <label htmlFor="password">Password:</label>
                            <input type="password" id="password" name="password" value ={password} required onChange={e => setPassword(e.target.value)} onBlur={validatePassword} /><br />
                            {passwordError && <div className="error">{passwordError}</div>}
                            <label htmlFor="confirmPassword">Confirm Password:</label>
                            <input type="password" id="confirmPassword" name="confirmPassword" value={confirmPassword} required onChange={e => setConfirmPassword(e.target.value)} onBlur={validateConfirmPassword} /><br />
                            {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                            <input type="submit" value="Save Changes" onClick={handleUpdatePassword} />
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
}
else {
  return <Navigate to="/" replace={true} />
};
};

export default StudentProfile;
