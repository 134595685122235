import React, { useState, useEffect } from 'react';
import '../css/student_dashboard.css';
import HeaderLoggedIn from '../components/headerLoggedIn';
import Footer from '../components/footer';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { fetchEnrolledCoursesStudent, getStudentGrades , fetchCourses } from '../services/api';
import  UpcomingExams  from '../components/getUpcomingExams'
import PerformanceChart from '../components/PerformanceChart';

const StudentDashboard = () => {
    document.title = "Student Dashboard";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const studentId  = decodedToken.user_id;
    const [courses, setCourses] = useState([]);
    const [grades, setGrades] = useState([]);
    const [isViewPopupOpen, setViewPopupOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState({ Course_Name: '', Course_Description: '', Course_Syllabus: '' });
    const [coursesDetails, setCoursesDetails] = useState([]);

    useEffect(() => {
        fetchCoursesDetails();
        fetchEnrolledCoursesStudent(studentId).then(data => {
            setCourses(data);
        }).catch(error => {
            console.error("Error fetching enrolled courses:", error);
        })
    }, [studentId]);

    const fetchCoursesDetails = async () => {
        try {
            const coursesData = await fetchCourses();
            setCoursesDetails(coursesData);
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    }
    const openViewPopup = (policy) => {
        setSelectedCourse(policy);
        setViewPopupOpen(true);
      };
    
      const handleView = (Course_Name) => {
        debugger;
        const policyToUpdate = coursesDetails.find((courses) => courses.Course_Name === Course_Name);
        if (policyToUpdate) {
          openViewPopup(policyToUpdate);
        }
      };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const gradesData = await getStudentGrades(studentId);
                setGrades(gradesData);
            } catch (error) {
                console.error("Error fetching grades:", error);
            }
        };
        fetchData();
    }, [studentId]);
    const view_profile_status = decodedToken.permissions.view_profile=== '0'? false : true;
  
    if ( role === 'student' ) {
      return (
      <>
      <HeaderLoggedIn role ={role}/>
      
            <div className="content-container">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />
                <div className="flex head">
                    <h2>Welcome, student</h2>
                    <div className="flex icons">
                        {/* <i className="fa-regular fa-bell"></i> */}
                        <div className="profile flex dropbtn">
                            <i className="fa-regular fa-user"></i>
                            <div className="dropdown-content">
                            <a href={view_profile_status === true ? '/student_profile' : undefined} onClick={view_profile_status === false ? () => alert('You do not have permission to view profile') : undefined}>
                               Profile Setting</a>
                               <a href="/" target="_top" onClick={() => localStorage.removeItem('token')}>Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="containerStudentDashboard">
                    <div className="left" id="style-2">
                        <h3 className="title">Enrolled Courses</h3>
                        {/* <div className="courseList">
                            <div className="box course">
                                <h4>Web development</h4>
                                <h5>Instructor: Robert</h5>
                                <a href="course.html">view more details <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                            <div className="box course">
                                <h4>Software Development</h4>
                                <h5>Instructor: Robert</h5>
                                <a href="course.html">view more details <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                            <div className="box course">
                                <h4>Software Development</h4>
                                <h5>Instructor: Robert</h5>
                                <a href="course.html">view more details <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div> */}
                           <div className="courseList">
                {courses.map(course => (
                    <div key={course.Course_Name} className="box course">
                        <h4>{course.Course_Name}</h4>
                        <h5>Instructor: {course.Instructor_First_Name} {course.Instructor_Last_Name}</h5>
                        <button className="update-button" onClick={() => handleView(course.Course_Name)}>view more details <i className="fa-solid fa-arrow-right"></i></button>
                    </div>
                ))}
            </div>
                    </div>
                    <div className="center" id="style-2">
                        <div className="classHeader">
                            <h3 className="classTitle">Overall Performance</h3>
                            {/* <span className="icon-group">
                                <i className="fa-regular fa-calendar-days"></i>
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span> */}
                        </div>
                        <PerformanceChart studentId={studentId} />
                        {/* <div className="classsConatiner">
                            <div className="classes box">
                                <span className="flex">
                                    <h4 className="subjectName">Mathematics</h4>
                                    <span className="classHeader">
                                        9:00 AM
                                        <i className="fa-regular fa-clock"></i>
                                    </span>
                                </span>
                                <h5 className="professor">Professor John</h5>
                                <p className="subjectContent">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry</p>
                            </div>
                            <div className="classes box">
                                <span className="flex">
                                    <h4 className="subjectName">Computer Architecture</h4>
                                    <span className="classHeader">
                                        9:00 AM
                                        <i className="fa-regular fa-clock"></i>
                                    </span>
                                </span>
                                <h5 className="professor">Professor John</h5>
                                <p className="subjectContent">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="right" id="style-2">
                        <h3 className="newsTitle title">News & updates</h3>
                        <div>
                            <div className="newsBox">
                                <img src="https://www.compassroseevents.com/wp-content/uploads/2015/04/iStock-476949098-1800x700.jpg"
                                    alt="cover picture" />
                                <p>
                                    University announces the event contrary to popular belief, Lorem Ipsum is not simply random
                                    text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000
                                    years old.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="containerStudentDashboard">
                    {/* <div className="tablediv">
                        <h3 className="title">Grades Posted</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Subject Name</th>
                                    <th>Pending</th>
                                    <th>Earliest deadline</th>
                                </tr>
                                <tr>
                                    <td>Maths</td>
                                    <td>2 tasks</td>
                                    <td>24th Oct</td>
                                </tr>
                                <tr>
                                    <td>Maths 2</td>
                                    <td>2 tasks</td>
                                    <td>24th Oct</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                     <div className="tablediv">
            <h3 className="title">Grades Posted</h3>
            <table>
                <tbody>
                    <tr>
                        <th>Course Name</th>
                        <th>Exam Date</th>
                        <th>Grade</th>
                    </tr>
                    {grades.map((grade, index) => (
                        <tr key={index}>
                            <td>{grade.Course_Name}</td>
                            <td>{grade.Exam_Date}</td>
                            <td>{grade.Grade}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
                    {/* <div className="projectsContainer" id="style-2">
                        <span className="projectContainerHeader">
                            <h3 className="projectTitle title">Exams Schedule</h3>
                        </span>
                        <div className="project box">
                            <span className="projectHeader">
                                <h4>WDM Project</h4>
                                <i className="fa-brands fa-github"></i>
                            </span>
                            <h5>09-27-2023</h5>
                            <a>View more <i className="fa-solid fa-arrow-right"></i></a>
                        </div>
                        <div className="project box">
                            <span className="projectHeader">
                                <h4>WDM Project 2</h4>
                                <i className="fa-brands fa-github"></i>
                            </span>
                            <h5>09-27-2023</h5>
                            <a>View more <i className="fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div> */}
                    <UpcomingExams />
                </div>
            </div>
            {isViewPopupOpen && (
          <div className={`update-policy-popup ${isViewPopupOpen ? 'visible' : ''}`}>
            <div className="course-popup-content">
              <h3>
                Course Name:</h3>
                <div>{selectedCourse.Course_Name}</div>
              <h3>
                Course Description:</h3>
                <div>{selectedCourse.Course_Description}</div>
              <h3>
                Course Syllabus:</h3>
                <div>
  {!selectedCourse.Syllabus? 'No syllabus available' : selectedCourse.Syllabus.split('\\n').join(' ')}
</div>
              <div className="popup-buttons">
                <button onClick={() => setViewPopupOpen(false)}>Close</button>
              </div>
            </div>
          </div>
        )}
            <Footer />
        </>
    );
}
else {
  return <Navigate to="/" replace={true} />
};
};

export default StudentDashboard;
