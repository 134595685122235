import React, { useState, useEffect, useRef } from 'react';
import { getStudentPerformance } from '../services/api';
import '../css/student_dashboard.css';

const PerformanceChart = ({ studentId }) => {
    const [performanceData, setPerformanceData] = useState([]);
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data = await getStudentPerformance(studentId);
    //             setPerformanceData(data);
    //         } catch (error) {
    //             console.error("Error fetching performance:", error);
    //         }
    //     };
    //     fetchData();
    // }, [studentId]);
    const computeAverageGrades = (data) => {
        const courseGrades = {};
    
        data.forEach(course => {
            if (courseGrades[course.Course_Name]) {
                courseGrades[course.Course_Name].push(course.Grade);
            } else {
                courseGrades[course.Course_Name] = [course.Grade];
            }
        });
    
        const averagedData = Object.entries(courseGrades).map(([course, grades]) => {
            const sum = grades.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            const average = sum / grades.length;
            return {
                Course_Name: course,
                Grade: Math.round(average)
            };
        });
    
        return averagedData;
    }
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getStudentPerformance(studentId);
                const averagedData = computeAverageGrades(data);
                setPerformanceData(averagedData);
            } catch (error) {
                console.error("Error fetching performance:", error);
            }
        };
        fetchData();
    }, [studentId]);

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        const ctx = chartRef.current.getContext('2d');
        chartInstance.current = new window.Chart(ctx, {
            type: 'bar',
            data: {
                labels: performanceData.map(item => item.Course_Name),
                datasets: [{
                    label: 'Grades',
                    data: performanceData.map(item => item.Grade),
                    // Giving a different background color for each bar
                    backgroundColor: performanceData.map(() => `hsla(${Math.random() * 360}, 100%, 75%, 1)`),
                    borderColor: '#2979FF',
                    borderWidth: 1
                }]
            }
        });
    }, [performanceData]);

    useEffect(() => {
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, []);

    return (
        <div className="classes box">
            {/* Adjusting the size of the canvas to make the chart smaller */}
            <canvas ref={chartRef} style={{ width: '100%', height: '100px' }}></canvas>
            <div className="classes box">
                {performanceData.map((course, index) => (
                    <div key={index}>
                        <span className="flex">
                            <h4 className="subjectName">{course.Course_Name}</h4>
                            <span className="classHeader">
                                Grade: {course.Grade}
                            </span>
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PerformanceChart;
