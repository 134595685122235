import React, { useState, useEffect } from 'react';
import { fetchUserMessages, sendMessage, fetchSenderName, fetchUserListforChat } from '../../services/api'; // Import the API service functions
import './chat.css';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import jwt_decode from 'jwt-decode';

function Chat() {
    document.title = "Chat";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const senderId = decodedToken.user_id;
    const role = decodedToken.role.toLowerCase();
    const [receiverName, setReceiverName] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [receiverId, setReceiverId] = useState(null);

    // Function to fetch messages and refresh the messages state
    const fetchAndRefreshMessages = () => {
        if (selectedUser) {
            const newReceiverId = selectedUser.User_ID;
            fetchUserMessages(senderId, newReceiverId)
                .then((data) => {
                    setMessages(data);
                })
                .catch((error) => {
                    console.error('Error fetching messages:', error);
                });
        }
    };


    // Function to fetch the list of users
    const fetchUsers = () => {
        fetchUserListforChat(senderId, role)
            .then(data => {
                if (data.length > 0) {
                    setUsers(data);
                }
                else {
                    setUsers([]);
                }
            })
            .catch(error => {
                console.error('Error fetching user list:', error);
            });
    };
    const handleUserSelect = (selectedUser) => {
        setSelectedUser(selectedUser);
        setReceiverId(selectedUser.User_ID); // Assuming User_ID is the unique identifier
        fetchReceiverInfo();
        fetchAndRefreshMessages();
    };
    const fetchReceiverInfo = () => {
        fetchSenderName(receiverId)
            .then(data => {
                setReceiverName(data.name);
            })
            .catch(error => {
                console.error('Error fetching sender name:', error);
            });
    };
    // Function to poll for new messages
    const pollForNewMessages = () => {
        if (selectedUser) {
            const intervalId = setInterval(() => {
                fetchUserMessages(senderId, selectedUser.User_ID)
                    .then((data) => {
                        setMessages(data);
                    })
                    .catch((error) => {
                        console.error('Error fetching messages:', error);
                    });
            }, 5000); // Poll every 5 seconds (adjust the interval as needed)
            return intervalId;
        }
    };
    useEffect(() => {
        // Fetch the sender's name when the component mounts
        fetchReceiverInfo();
        fetchUsers();
        // Fetch messages when the component mounts
       // Fetch messages when the component mounts
       fetchAndRefreshMessages();

       // Start polling for new messages when a user is selected
       const pollingIntervalId = pollForNewMessages();

       return () => {
           // Clear the interval when the component unmounts to prevent memory leaks
           if (pollingIntervalId) {
               clearInterval(pollingIntervalId);
           }
       };
   }, [selectedUser]); 

    const handleSendMessage = () => {
        sendMessage({ messageContent: newMessage, senderId, receiverId })
            .then(() => {
                setNewMessage('');
                fetchAndRefreshMessages();
            })
            .catch(error => {
                console.error('Error sending message:', error);
            });
        setNewMessage('');
    };
    return (
        <>
            <HeaderLoggedIn role={role} />
            <div className="content-containerchat">

                <div className="left-panelchat">
                    <div className="sectionchat">
                        <div className="section-title">Select Users</div>
                        <div className="section-content">

                            <ul className="user-list" id="userList">
                                {users.map((user) => (
                                    <li
                                        key={user.User_ID}
                                        className="user-list-item"
                                        onClick={() => handleUserSelect(user)}
                                    >
                                        <div>
                                            <div className="user-name">
                                                {user.First_Name} {user.Last_Name} ({senderId === user.User_ID ? 'Self' : user.Role})
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="right-containerchat">

                    <h2>MESSAGE TO: {receiverName}</h2>
                    {receiverName !== 'None Selected' && (
                        <div className="right-panelchat">
                            {messages.map((msg, index) => (
                                <div
                                    key={index}
                                    className={` ${msg.Sender_ID === senderId ? 'my-message-container' : 'other-message-container'
                                        }`}
                                >
                                    <div key={index} className={`message ${msg.Sender_ID === senderId ? 'my-message' : 'other-message'}`}>
                                        <div className="sender">{msg.Sender_ID === senderId ? 'you' : receiverName}</div>
                                        <div >{msg.Message_Content}</div>
                                    </div>
                                </div>
                            ))}
                            <div className="input-container">
                                <textarea
                                    placeholder="Type your message here"
                                    value={newMessage}
                                    onChange={e => setNewMessage(e.target.value)}
                                />
                                <button onClick={handleSendMessage}>Send</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Chat;
