import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import './modules.css';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { uploadFiles, listFiles, downloadFile, deleteFiles, viewFile, updateSectionName } from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';

const Modules = () => {
    const courseId = "1"; // Example Course ID - replace with the real ID or fetch it as required

    const [sections, setSections] = useState([]);
    const [selectedFilesToDelete, setSelectedFilesToDelete] = useState([]);
    const [editingSection, setEditingSection] = useState(null);
    const inputRef = useRef(null);
    const { course_id } = useParams();

    const [editedSectionNames, setEditedSectionNames] = useState({});


    useEffect(() => {
        const fetchInitialFiles = async () => {
            try {
                const data = await listFiles(course_id);
                setSections(data);
            } catch (error) {
                console.error("Error fetching files:", error);
            }
        };

        fetchInitialFiles();
    }, [course_id]);

    const handleAddSection = () => {
        const newSection = {
            key: uuidv4(), // This will generate a unique UUID for each new section
            name: `New Section`,
            files: []
        };
        setSections(prevSections => [...prevSections, newSection]);
    };

    const handleUpload = async (sectionKey, event) => {
        const files = Array.from(event.target.files);
        // Find the section by its key to get the name
        const section = sections.find(s => s.key === sectionKey);
        const sectionName = section.name; // This is the name you want to store in the database

        // Update the local state with the new files
        setSections(prevSections => {
            return prevSections.map(s => {
                if (s.key === sectionKey) {
                    return { ...s, files: [...s.files, ...files] };
                }
                return s;
            });
        });

        // Immediately upload the files after setting the state
        try {
            const response = await uploadFiles(sectionName, course_id, files);
            if (response?.success) {
                showBanner(response.success, 'success');
            } else {
                showBanner(response.error || 'Failed to upload', 'failure');
            }
        } catch (error) {
            showBanner(error || 'Failed to upload', 'failure');
        }
    };


    const handleNameChange = (sectionKey, event) => {
        const newName = event.target.value;
        setEditedSectionNames(prevNames => ({ ...prevNames, [sectionKey]: newName }));
    };


    const handleNameUpdate = async (sectionKey) => {
        const newName = editedSectionNames[sectionKey];
        const section = sections.find(s => s.key === sectionKey);
        if (!section) {
            console.error(`Section with key ${sectionKey} not found`);
            return;
        }

        const oldName = section.name;
        if (newName.trim() === oldName.trim()) {
            console.log('New name is the same as the old name, no update needed.');
            return;
        }

        // Only proceed with API call if there are file IDs to update
        if (section.files.length > 0) {
            const fileIds = section.files.map(file => file.id);
            try {
                const result = await updateSectionName(course_id, oldName, newName, fileIds);
                if (result && result.message) {
                    showBanner(result.message, 'success');
                    console.log(result.message);
                    // Update the section name in the local state
                    setSections(prevSections => {
                        return prevSections.map(s => {
                            if (s.key === sectionKey) {
                                return { ...s, name: newName };
                            }
                            return s;
                        });
                    });
                    // Reset the edited name for the section
                    setEditedSectionNames(prevNames => {
                        const newNames = { ...prevNames };
                        delete newNames[sectionKey];
                        return newNames;
                    });
                } else if (result && result.error) {
                    showBanner(result.error || 'Failed to update', 'failure');
                    console.error(result.error);
                }
            } catch (error) {
                showBanner(error || 'Error updating section name', 'failure');
                console.error("Error updating section name:", error);
            }
        } else {
            console.log(`No files in section ${sectionKey}, skipping database update.`);
            // If no files, still update the name in the state
            setSections(prevSections => {
                return prevSections.map(s => {
                    if (s.key === sectionKey) {
                        return { ...s, name: newName };
                    }
                    return s;
                });
            });
            // Reset the edited name for the section
            setEditedSectionNames(prevNames => {
                const newNames = { ...prevNames };
                delete newNames[sectionKey];
                return newNames;
            });
        }
    };




    const handleDownload = (fileId) => {
        downloadFile(fileId);
    };

    const handleSaveChanges = async () => {
        for (const section of sections) {
            try {
                await uploadFiles(section.name, course_id, section.files);

            } catch (error) {
                console.error("Error uploading files:", error);
            }
        }
    };

    const handleFileSelection = (fileId) => {

        setSelectedFilesToDelete(prevState => {
            if (prevState.includes(fileId)) {
                return prevState.filter(id => id !== fileId);
            } else {
                return [...prevState, fileId];
            }
        });
    };

    const handleDeleteSelectedFiles = async () => {
        try {

            const response = await deleteFiles(selectedFilesToDelete);
            if (response?.message) {
                showBanner(response.message, 'success');
            } else {
                showBanner(response.error || 'Failed to delete files', 'failure');
            }

            // Create a new sections array by filtering out the deleted files
            const updatedSections = sections.map(section => {
                return {
                    ...section,
                    files: section.files.filter(file => !selectedFilesToDelete.includes(file.id))
                };
            });



            setSections(updatedSections);  // Update the sections state
            setSelectedFilesToDelete([]);  // Clear the selection


        } catch (error) {
            console.error("Error deleting selected files:", error);
        }
    };

    const handleDeleteSection = async (sectionKey) => {
        const sectionToDelete = sections.find(section => section.key === sectionKey);
        if (sectionToDelete && sectionToDelete.files.length > 0) {
            const fileIdsToDelete = sectionToDelete.files.map(file => file.id);
            try {
                const response = await deleteFiles(fileIdsToDelete);
                if (response?.message) {
                    showBanner(response.message, 'success');
                } else {
                    showBanner(response.error || 'Failed to delete files', 'failure');
                }
            } catch (error) {
                console.error(`Error deleting files for section ${sectionKey}:`, error);
            }
        }
        setSections(prevSections => prevSections.filter(section => section.key !== sectionKey));
    };

    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const [banner, setBanner] = useState({ message: '', type: '' });

    const showBanner = (message, type) => {
        setBanner({ message, type });
        // Automatically hide the banner after 3 seconds
        setTimeout(() => setBanner({ message: '', type: '' }), 3000);
    };

    if (role === 'instructor') {
        return (
            <>
                <>
                    {banner.message && (
                        <div className={`banner banner-${banner.type}`}>
                            {banner.message}
                        </div>
                    )}
                    {/* Rest of your component */}
                </>
                <HeaderLoggedIn role={role} />
                <div className="modulesContainer">
                    <div className="modules-main-content">
                        <div className="modules-nav">
                            <Link to={`/instructor/coursehome/${course_id}`} >Home</Link>
                            <Link to={`/instructor/announcments/${course_id}`} >Announcements</Link>
                            <Link to={`/instructor/syllabus/${course_id}`}>Syllabus</Link>
                            <Link to={`/instructor/modules/${course_id}`} className="modules-selected">Modules</Link>
                            <Link to={`/instructor/exams/${course_id}`} >Exams</Link>
                            <Link to={`/instructor/grades/${course_id}`}>Grades</Link>
                            <Link to={`/instructor/people/${course_id}`}>People</Link>
                            <Link to={`/instructor/reports/${course_id}`}>Reports</Link>
                        </div>
                        <div className="modules-content">
                            <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                                <button
                                    id="addSectionBtn"
                                    aria-label="Add new section"
                                    className="add-section-btn"
                                    onClick={handleAddSection}
                                >
                                    ➕ Add Section
                                </button>
                            </div>
                            {sections.map((section, index) => (
                                <div key={section.key} className={section.key}>
                                    <div className="section-header">
                                        <input
                                            ref={(el) => {
                                                if (!inputRef.current) {
                                                    inputRef.current = [];
                                                }
                                                inputRef.current[index] = el;
                                            }}
                                            className="section-header-title"
                                            value={editedSectionNames[section.key] || section.name}
                                            readOnly={editingSection !== section.key}
                                            onBlur={() => {
                                                if (editingSection === section.key) {
                                                    const newName = editedSectionNames[section.key] || section.name;
                                                    handleNameUpdate(section.key, newName);
                                                    setEditingSection(null);
                                                    // Clean up the edited name from the state
                                                    setEditedSectionNames(prevNames => {
                                                        const newNames = { ...prevNames };
                                                        delete newNames[section.key];
                                                        return newNames;
                                                    });
                                                }
                                            }}
                                            onClick={() => {
                                                setEditingSection(section.key);
                                                setEditedSectionNames(prevNames => ({ ...prevNames, [section.key]: prevNames[section.key] || section.name }));
                                                if (inputRef.current && inputRef.current[index]) {
                                                    inputRef.current[index].focus();
                                                }
                                            }}
                                            onChange={(e) => handleNameChange(section.key, e)}
                                        />

                                        <span
                                            className="edit-icon"
                                            onClick={() => {
                                                setEditingSection(section.key);
                                                if (inputRef.current && inputRef.current[index]) {
                                                    inputRef.current[index].focus();
                                                }
                                            }}
                                        >
                                            ✏️
                                        </span>
                                        <button
                                            className="upload-btn"
                                            onClick={() => document.getElementById(`upload-${section.key}`).click()}
                                        >
                                            Upload
                                        </button>
                                        <span className="delete-icon" onClick={() => handleDeleteSection(section.key)}>🗑️</span>
                                        <input
                                            id={`upload-${section.key}`}
                                            type="file"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleUpload(section.key, e)}
                                        />
                                    </div>
                                    <table className="file-table">
                                        <tbody>
                                            {section.files.map((file, fileIndex) => (
                                                <tr key={file.id}>
                                                    <td>
                                                        <input type="checkbox" onChange={() => handleFileSelection(file.id)} />
                                                    </td>
                                                    <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        {file.name}
                                                        <span>
                                                            <FontAwesomeIcon icon={faEye} onClick={() => viewFile(file.id)} style={{ cursor: 'pointer', margin: '0 8px' }} />
                                                            <FontAwesomeIcon icon={faDownload} onClick={() => handleDownload(file.id)} style={{ cursor: 'pointer' }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                            {selectedFilesToDelete.length > 0 && (
                                <button className="delete-selected-btn" onClick={handleDeleteSelectedFiles}>Delete Selected</button>
                            )}

                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    else {
        return <Navigate to="/" replace={true} />
    };
};

export default Modules;
