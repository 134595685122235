import React,{useState} from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import '../../css/auth.css';
import { sendForgotPasswordEmail, updatePassword } from '../../services/api';


const ForgotPassword = () => {
    const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState(''); // To show the success/error message after email is sent
const [showResetInputs, setShowResetInputs] = useState(false); // To show the reset password inputs after the reset link is clicked
const [tempPassword, setTempPassword] = useState(''); 
const [newPassword, setNewPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');
  


const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      setEmailError('Email is required.');
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError('Invalid email format.');
      return false;
    }
    setEmailError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail()) {
      // Add code to send a reset email if the email validation passes.
      try {
        const result = await sendForgotPasswordEmail(email);
        if(result.success) {
          setMessage(result.message); // Display the success message
          setShowResetInputs(true); // Show the reset password inputs
        } else {
          setEmailError(result.message); // Display the error message
        }
      } catch (error) {
        setEmailError("An error occurred. Please try again later.");
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Passwords don't match.");
      return;
    }
    
    try {
      const result = await updatePassword(email, tempPassword, newPassword);
      if (result.success) {
        setMessage(result.message);
        setShowResetInputs(false); // Hide reset inputs upon success
        // redirect to login page after 3 seconds
        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 3000);
      } else {
        setMessage(result.message);
      }
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
    }
  };
  

    document.title = "Forgot Password";
    return (
        <>
            <Header />
            
            <div className="main-content">
            <div className="container_shrd">
            {message && <div className="message">{message}</div>}                
            {!showResetInputs ? (
              <>
            <h2 className='c_h2'>Forgot Password ?</h2>
        <p>Please enter your email address and we'll send you link to reset your forgotten password.</p>
        <form id="forgot-password-form" onSubmit={handleSubmit}>
        <input
              type="email"
              placeholder="Email Address"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={validateEmail} // Call validation function when the input loses focus
            />
            {emailError && <div className="error">{emailError}</div>}
            <button type="submit">Send Reset Email</button>
        </form>
        </>
        ) : (
          <>
          <h2 className='c_h2'>Reset Password</h2>
          <p>Please enter your new password.</p>
          <form id="reset-password-form" onSubmit={handleResetPassword}>
  <input
    type="text"
    placeholder="Temporary Password"
    required
    value={tempPassword}
    onChange={(e) => setTempPassword(e.target.value)}
  />
  <input
    type="password"
    placeholder="New Password"
    required
    value={newPassword}
    onChange={(e) => setNewPassword(e.target.value)}
  />
  <input
    type="password"
    placeholder="Confirm Password"
    required
    value={confirmPassword}
    onChange={(e) => setConfirmPassword(e.target.value)}
  />
  <button type="submit">Reset Password</button>
</form>

          </>
        )}

        <button type="button" className="backtosignin">
            <a href="./login" className="back-to-login">Back to Sign In</a>
        </button>
            </div>
                {/* Put the footer at absolute bottom */}
                <div className="footer-push">
                <Footer />
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
