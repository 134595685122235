import React,{useState, useEffect} from 'react';

import '../css/coursescommon.css';
import { getAllCoursesWithInstructors } from '../services/api';



// CourseWidget Component
const CourseWidget = ({ title, instructor }) => {
    return (
        <div className="course-widget" style={{ padding: '10px', margin: '10px' }}>
            <h3>{title}</h3>
            <h5>{instructor}</h5>
        </div>
    );
};

// CoursesCommon Component
const CoursesCommon = () => {

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchCourses = async () => {
          try {
            const courseData = await getAllCoursesWithInstructors();
            setCourses(courseData);
          } catch (error) {
            console.error("Error fetching courses:", error);
          }
        };
    
        fetchCourses();
      }, []);
    

    // const courses = [
    //     { title: "Introduction to Programming", instructor: "Instructor: Dr. Jone Remory" },
    //     { title: "Data Structures and Algorithms", instructor: "Instructor: Prof. Mary" },
    //     { title: "Database Management Systems", instructor: "Instructor: Chance Eary" },
    //     { title: "Secure Programming", instructor: "Instructor: Pro. Thomas" },
    //     { title: "PLC", instructor: "Instructor: Dr. John Doe" },
    //     { title: "UI", instructor: "Instructor: Dr. Sally" },
    // ];

    return (
        <div className="courses-section">
            <div className="course-row" style={{ padding: '10px', margin: '10px' }}>
                {courses.map(course => {
                    const instructor = `${course.Instructor_First_Name} ${course.Instructor_Last_Name}`;  // <-- You can use template literals to concatenate strings
                    return <CourseWidget key={course.Course_Name} title={course.Course_Name} instructor={instructor} />
                })}
            </div>
        </div>
    );
};

export default CoursesCommon;
