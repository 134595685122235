import React from 'react';
import { useEffect, useState } from 'react';
import { fetchProgramObjectives, fetchCourseDescription, saveCourseDescription } from '../../services/api';

import { useParams } from 'react-router-dom';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import './courseHome.css';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const CourseHome = () => {
    document.title = "Course Home";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const { course_id } = useParams();

    const [objectives, setObjectives] = useState([]);
    const [description, setDescription] = useState("");

    const [isEditing, setIsEditing] = useState(false);
    const [editDescription, setEditDescription] = useState(description);
    const [banner, setBanner] = useState({ message: '', type: '' });

    const showBanner = (message, type) => {
        setBanner({ message, type });
        // Automatically hide the banner after 3 seconds
        setTimeout(() => setBanner({ message: '', type: '' }), 3000);
    };


    useEffect(() => {
        setEditDescription(description);
    }, [description]);

    const handleSaveDescription = async () => {
        try {
            const response = await saveCourseDescription(course_id, editDescription);
            if (response?.message) {
                setDescription(editDescription);
                showBanner(response.message, 'success');
            } else {
                console.error('Failed to save description:', response.error);
                showBanner(response.error || 'Failed to save description:', 'failure');
            }
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving description:', error);
            showBanner(error || 'Error saving description:', 'failure');
        }
    };

    useEffect(() => {
        async function fetchObjectives() {
            try {
                const data = await fetchProgramObjectives(course_id);
                const extractedObjectives = data.map(item => item.Objective_Name);
                setObjectives(extractedObjectives);
            } catch (error) {
                console.error("Error fetching objectives:", error);
            }
        }
        fetchObjectives();

        async function fetchDescription() {
            try {
                const data = await fetchCourseDescription(course_id);
                setDescription(data.Course_Description);
            } catch (error) {
                console.error("Error fetching course description:", error);
            }
        }
        fetchDescription();
    }, []);


    if (role === 'instructor') {
        return (
            <>
                <>
                    {banner.message && (
                        <div className={`banner banner-${banner.type}`}>
                            {banner.message}
                        </div>
                    )}
                    {/* Rest of your component */}
                </>
                <HeaderLoggedIn role={role} />
                <div className="courseHomeContainer">
                    <div className="courseHome-main-content">
                        <div className="courseHome-nav">
                            <Link to={`/instructor/coursehome/${course_id}`} className="courseHome-selected">Home</Link>
                            <Link to={`/instructor/announcments/${course_id}`} >Announcements</Link>
                            <Link to={`/instructor/syllabus/${course_id}`}>Syllabus</Link>
                            <Link to={`/instructor/modules/${course_id}`}>Modules</Link>
                            <Link to={`/instructor/exams/${course_id}`}>Exams</Link>
                            <Link to={`/instructor/grades/${course_id}`}>Grades</Link>
                            <Link to={`/instructor/people/${course_id}`}>People</Link>
                            <Link to={`/instructor/reports/${course_id}`}>Reports</Link>
                        </div>
                        <div className="courseHome-content">
                            <div className="courseHome-section">

                                <h2>Objectives</h2>
                                {objectives.map((objective, index) => (
                                    <p key={index}>{objective}</p>
                                ))}

                            </div>
                            <div className="courseHome-section">
                                <h2>About Course</h2>
                                {isEditing ? (
                                    <textarea
                                        className="description-edit"
                                        value={editDescription}
                                        onChange={(e) => setEditDescription(e.target.value)}
                                    />
                                ) : (
                                    <p>{description}</p>
                                )}
                                {isEditing ? (
                                    <button className="save-description-btn" onClick={handleSaveDescription}>
                                        Save
                                    </button>
                                ) : (
                                    <button className="edit-description-btn" onClick={() => setIsEditing(true)}>
                                        Edit
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
    else {
        return <Navigate to="/" replace={true} />
    };
};

export default CourseHome;
