import React, {useState} from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import '../../css/auth.css';
import { registerUser } from '../../services/api';

const Register = () => {
    document.title = "Register";
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [dob, setDob] = useState('');
    const [role, setRole] = useState('');
    const [mobile, setMobile] = useState('');

    const [firstNameError, setFirstNameError] = useState('');
const [lastNameError, setLastNameError] = useState('');
const [emailError, setEmailError] = useState('');
const [passwordError, setPasswordError] = useState('');
const [confirmPasswordError, setConfirmPasswordError] = useState('');
const [roleError, setRoleError] = useState('');
const [mobileError, setMobileError] = useState('');


const validateFirstName = () => {
    if (!firstName) {
      setFirstNameError('First name is required.');
      return false;
    } else if (firstName.length < 2) {
      setFirstNameError('First name should be at least 2 characters.');
      return false;
    }
    setFirstNameError('');
    return true;
  };
  const validateRole = () => {
    if (!role) {
        setRoleError('Role is required.');
        return false;
    }
    setRoleError('');
    return true;
};


    const validateLastName = () => {
        if (!lastName) {
            setLastNameError('Last name is required.');
            return false;
            } else if (lastName.length < 2) {
            setLastNameError('Last name should be at least 2 characters.');
            return false;
            }
            setLastNameError('');
            return true;
        };

  
  const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      setEmailError('Email is required.');
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError('Invalid email format.');
      return false;
    }
    setEmailError('');
    return true;
  };

  const validateMobile = () => {
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobile) {
        setMobileError('Mobile is required.');
        return false;

    } else if (!mobileRegex.test(mobile)) {
        setMobileError('Invalid mobile format.');
        return false;
    }
    setMobileError('');
    return true;

};


  
    const validatePassword = () => {
        if (!password) {
            setPasswordError('Password is required.');
            return false;
            } else if (password.length < 6) {
            setPasswordError('Password should be at least 6 characters.');
            return false;
            }
            setPasswordError('');
            return true;
        };

        const validateConfirmPassword = () => {
            if (!confirmPassword) {
                setConfirmPasswordError('Confirm Password is required.');
                return false;
                } else if (confirmPassword.length < 6) {
                setConfirmPasswordError('Confirm Password should be at least 6 characters.');
                return false;
                }
                setConfirmPasswordError('');
                return true;
            }

            // check if password and confirm password are same
            const validatePasswordMatch = () => {
                if (password !== confirmPassword) {
                    setConfirmPasswordError('Passwords do not match.');
                    return false;
                }
                setConfirmPasswordError('');
                return true;
            }

            const validateForm = () => {
                const isFirstNameValid = validateFirstName();
                const isLastNameValid = validateLastName();
                const isEmailValid = validateEmail();
                const isPasswordValid = validatePassword();
                const isConfirmPasswordValid = validateConfirmPassword();
                const isPasswordMatch = validatePasswordMatch();
                const isRoleValid = validateRole();
                const isMobileValid = validateMobile();
                return (
                    isFirstNameValid &&
                    isLastNameValid &&
                    isEmailValid &&
                    isPasswordValid &&
                    isConfirmPasswordValid &&
                    isPasswordMatch &&
                    isRoleValid &&
                    isMobileValid
                );
            };


    const handleRegistration = () => {
        if(!validateForm()) {
            return; // stop the registration if form is not valid
        }
        registerUser({ firstName, lastName, email, password, confirmPassword, role, dob, mobile })
          .then(response => {
            // window.location.href = '/login';
            if (response.message) {
                
              window.alert(response.message);
              if (response.message === 'User registered successfully') {
                // Optionally, redirect to login or a different page after successful registration
                window.location.href = '/auth/login';
              }
            } else {
              window.alert("There was an issue registering. Please try again.");
            }
          })
          .catch(error => {
            console.error('Error during registration:', error);
          });
      };

    return (
        <>
            <Header />
            
            <div className="main-content">
            <div className="container-reg">   
            <h2 className='c_h2'>Register Now</h2>             
            <form id="forgot-password-form" className="form active">
                <input type="text" placeholder="First Name" value={firstName} required onChange={e => setFirstName(e.target.value)} onBlur={validateFirstName} />
                {firstNameError && <div className="error">{firstNameError}</div>}
                <input type="text" placeholder="Last Name" required value={lastName} onChange={e => setLastName(e.target.value)} onBlur={validateLastName} />
                {lastNameError && <div className="error">{lastNameError}</div>}
                <input type="email" placeholder="Email ID" required value={email} onChange={e => setEmail(e.target.value)} validate onBlur={validateEmail} />
                {emailError && <div className="error">{emailError}</div>}
                <input type="date"  required id="dob" name="dob" value={dob} onChange={e => {setDob(e.target.value)}} /> 
                   
                <input type="text" placeholder='Mobile Number'  required id="mobile" name="mobile"
                    pattern="[0-9]{10}" title="Please enter a valid 10 digit mobile number" value={mobile} onChange={e => setMobile(e.target.value)} onBlur={validateMobile} />
                {mobileError && <div className="error">{mobileError}</div>}
                <input type="password" placeholder="Password" required value={password} onChange={e => setPassword(e.target.value)} onBlur={validatePassword} />
                {passwordError && <div className="error">{passwordError}</div>}
                <input type="password" placeholder="Confirm Password" value={confirmPassword} required onChange={e => setConfirmPassword(e.target.value)} onBlur={validateConfirmPassword} />
                {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                {/* <label for="dob"
                >Date of Birth:</label> */}
     
                {/* Create 5 Radio buttobs with label ROLE Values: Student, Instructor, Admin, Program Coordinator, QA Officer */}
                <div className="radio-group">
    <p>Role:</p>

    {roleError && <div className="error">{roleError}</div>}
    <div className="radio-option">
        <input type="radio" id="student" name="role" value="student"  onChange={e => setRole(e.target.value)} />
        <label htmlFor="student">Student</label>
    </div>
    
    <div className="radio-option">
        <input type="radio" id="instructor" name="role" value="instructor" onChange={e => setRole(e.target.value)} />
        <label htmlFor="instructor">Instructor</label>
    </div>

    <div className="radio-option">
        <input type="radio" id="admin" name="role" value="admin" onChange={e => setRole(e.target.value)} />
        <label htmlFor="admin">Admin</label>
    </div>

    <div className="radio-option">
        <input type="radio" id="program-coordinator" name="role" value="coordinator" onChange={e => setRole(e.target.value)} />
        <label htmlFor="program-coordinator">Program Coordinator</label>
    </div>

    <div className="radio-option">
        <input type="radio" id="qa-officer" name="role" value="qa" onChange={e => setRole(e.target.value)} />
        <label htmlFor="qa-officer">QA Officer</label>
    </div>
</div>

                <br />
                <br />
                <button type="button" onClick={handleRegistration}>Register Now</button>
                {/* add button to login.html */}
                <button type="button" className="forgot-password">
                    <a href="./login" style={{ color: '#FFF' }}>
                        Back to Sign In
                    </a>
                </button>
            </form>
            </div>
                {/* Put the footer at absolute bottom */}
                <div className="footer-push-reg">
                <Footer />
                </div>
            </div>
        </>
    );
}

export default Register;
