import React from 'react';
import '../css/style.css';

function Footer() {
    return (
        <footer className="footerContainer">
            <div className="footerSection">
                <ul>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/contact">Contact Us</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="http://sxk3354.uta.cloud/WDM-blog/">Blog</a></li>
                </ul>
            </div>
            <div className="footerLine"></div>
            <div className="footerSection">
                <div className="footerCopy">
                    <p className='copyright'>&copy; Group 11 WDM. 2023.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
