import React from 'react';
import '../css/style.css';
import '../css/header.css';
import { Navigate } from 'react-router-dom';
import HeaderLoggedIn from '../components/headerLoggedIn';
import jwt_decode from 'jwt-decode';
import Footer from '../components/footer';
import attendance from '../images/attendance_report.svg';

function AttendanceAnalytics() {
  document.title = "Attendance Analytics";
  const decodedToken = jwt_decode(localStorage.getItem('token'));
  const role = decodedToken.role.toLowerCase();

  // page not used for now
  return <Navigate to="/coordinator_dashboard" replace={true} />

  if (role === 'qa' || role === 'admin' || role === 'coordinator') {
    return (
    <>
    <HeaderLoggedIn role ={role}/>
    <div className="hk">
      <main style={{ padding: '20px' }}>
        <section>
          <h1 style={{ fontSize: '30px' }}>Attendance Analytics:</h1>
          <center> <img src={attendance} alt="Sample Bar Chart" width="50%" /> </center>
          <br /> <br />
          <div className="search-container">
            <input type="text" id="studentSearch" className="search-bar" placeholder="Search for a student..." />
            <button className="search-button">Search</button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Student ID</th>
                <th>Student Name</th>
                <th>Course</th>
                <th>Attendance Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>John Doe</td>
                <td>Mathematics</td>
                <td>85%</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jane Smith</td>
                <td>Science</td>
                <td>92%</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Mike Johnson</td>
                <td>History</td>
                <td>78%</td>
              </tr>
              {/* Add more attendance data as needed */}
            </tbody>
          </table>
          <br /><br />
        </section>
      </main>
    </div>
    <Footer/>
    </>
  );
}
else {
  return <Navigate to="/" replace={true} />
};
}

export default AttendanceAnalytics;
