import React, { useState, useEffect, useRef } from "react";
import { Navigate } from 'react-router-dom';
import HeaderLoggedIn from '../components/headerLoggedIn';
import { fetchCourses, fetchStudentGrades, fetchAnalyticsData } from "../services/api";
import jwt_decode from 'jwt-decode';
import Footer from '../components/footer';
import Chart from 'chart.js/auto';
import Plot from 'react-plotly.js';


function StudentReports() {
  document.title = "Student Reports";
  const decodedToken = jwt_decode(localStorage.getItem('token'));
  const role = decodedToken.role.toLowerCase();
  const [data, setData] = useState([]);
  const [reportType, setReportType] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [courses, setCourses] = useState([]);
  const [studentGrades, setStudentGrades] = useState([]);

  useEffect(() => {
    fetchAllCourses();
  }, [reportType]);

  // Function to fetch student grades for the selected course
  const fetchGrades = (courseId) => {
    if(!courseId) return;
    fetchStudentGrades(courseId)
    .then((responseData) => {
        // alert(responseData);
        setStudentGrades(responseData);
    })
    .catch((error) => alert("Sss"+error));
  };

  function fetchAllCourses() {
    // Fetch existing courses when the component mounts
    fetchCourses()
    .then((data) => setCourses(data))
    .catch((error) => console.error(error));

      fetchAnalyticsData("courseEffectiveness").then((responseData) => {
          setData(responseData);
      });
}

// Extract the average grades
const grades = studentGrades.map((student) => student.Average_Grade);

// Define the box plot trace
const boxPlotTrace = {
  y: grades,
  type: 'box',
  name: 'Grades',
  boxpoints: 'all', // Display individual data points
  jitter: 0.3, // Add jitter to data points for better visualization
  pointpos: -1.8, // Adjust the position of data points
  marker: { color: 'blue' },
};
// Define the layout for the box plot
const layout = {
  title: 'Box Plot of Student Grades',
};
// Render the chart
const renderChart = () => {
  if (data.length === 0) {
    return;
  }
  // Destroy the existing chart if it exists
  const existingChart = Chart.getChart("studentGradesChart");
  if (existingChart) {
    existingChart.destroy();
  }
  // Customize the font size and style
  const fontSize = 24; 
  const fontStyle = 'bold';

  const ctx = document.getElementById('studentGradesChart').getContext('2d');
    if (ctx.chart) {
      ctx.chart.destroy();
    }
  ctx.canvas.width = 200;
  ctx.canvas.height = 100;
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: data.map((course) => course.Course_Name),
        datasets: [
          {
            label: 'Average Grade',
            data: data.map((course) => parseFloat(course.Average_Grade).toFixed(2)),
            backgroundColor: 'rgba(0, 128, 255, 0.7)',
            borderColor: 'rgba(0, 128, 255, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            title : {
              display: true,
              text: 'Courses',
              font: {
                size: fontSize, // Set the desired font size
                style: fontStyle, // Set the desired font style
              },
            },
            ticks: {
              font: {
                size: 16, // Set the desired font size
                style: fontStyle, // Set the desired font style
              },
            }
          },
          y : {
            beginAtZero: true,
            title : {
              display: true,
              text: 'Average Grade',
              font: {
                size: fontSize, // Set the desired font size
                style: fontStyle, // Set the desired font style
              },
            },
            ticks: {
              stepSize: 10, // Customize the step size if needed
              fontSize: 14, // Set the desired font size
              fontStyle: fontStyle, // Set the desired font style
            },
          },
        },
      },
    });
  };


// Render the table
const renderTable = () => {
  if (!Array.isArray(studentGrades) || studentGrades.length === 0) {
    return;
  }
  return (
      <div>
          <table>
              <thead>
                  <tr>
                      <th>Student ID</th>
                      <th>Student Name</th>
                      <th>Total Grade in the Course</th>
                  </tr>
              </thead>
              <tbody>
                  {studentGrades.map((student) => (
                      <tr key={student.Student_ID}>
                          <td>{student.Student_ID}</td>
                          <td>{student.Student_Name}</td>
                          <td>{student.Average_Grade}%</td>
                      </tr>
                  ))}
              </tbody>
          </table>
      </div>
  );
};

const destroyChart = () => {
  const existingChart = Chart.getChart("studentGradesChart");
  if (existingChart) {
    existingChart.destroy();
  }
  const canvas = document.getElementById('studentGradesChart');
  canvas.width = 0;
  canvas.height = 0;
};

  if (role === 'qa' || role === 'admin' || role === 'coordinator') {
    return (
    <>
    <HeaderLoggedIn role ={role}/>
    <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <main style={{ padding: '20px', flex: 1 }}>
        <h1> Program Reports</h1>
        <section style={{width : '75%', margin : 'auto'}}>
        <div>
          <p style={{fontSize: '20px', marginBottom: '20px'}}>Generate reports for finding average grades of individual students for different courses and average grades per course of all the students enrolled in the program.   </p>
              <select
                className="select-report"
                value={reportType}
                onChange={(e) => {setReportType(e.target.value); setSelectedCourse(''); ; setSelectedStudent(''); destroyChart();}}
                style={{ width: '50%' }}
              >
                <option value="">Select a report type</option>
                <option value="singleCourse">Single Course</option>
                <option value="programEval">Program Evaluation</option>
              </select>
            </div>
        {reportType === "singleCourse" && (
        <select
          className="select-report"
          value={selectedCourse}
          onChange={(e) => {
            setSelectedCourse(e.target.value); 
            fetchGrades(e.target.value)
            destroyChart();
          }}
          style={{ width: '50%' }}
        >
          <option value="">Select a course</option>
          {courses.map((course) => (
            <option key={course.Course_ID} value={course.Course_ID}>
              {course.Course_Name}
            </option>
          ))}
        </select>
        )}
      {reportType === "programEval" && (
        renderChart()
      )}
      <canvas id="studentGradesChart" width="0" height="0"></canvas>
      {selectedCourse && studentGrades && studentGrades.length > 0 ? (
        <>
          <div>
            <h2>Box Plot of Student Grades in the Selected Course</h2>
            <center><Plot data={[boxPlotTrace]} layout={layout} /></center>
          </div>
          <h1> List of students who are enrolled in the course</h1>
          {renderTable()}
          <br></br>
        </>
      ) : selectedCourse ? (
        <>
        <p style={{ textAlign: 'center', paddingTop: '20px' }}>No data available for the selected course</p>
        {destroyChart()}
        </>
      ) : null}
    </section>
      </main>
      <Footer />
    </div>
    </>
  );
}
else {
  return <Navigate to="/" replace={true} />
};
}

export default StudentReports;
