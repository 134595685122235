import React from 'react';
import {School, Leaderboard, Chat, Group, Person} from '@mui/icons-material/';
import '../css/features.css';
import '../css/header.css';
import HeaderLoggedIn from '../components/headerLoggedIn';
import Footer from '../components/footer';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

function ProgramCoordinatorHomepage() {
  document.title = "Coordinator Dashboard";
  const decodedToken = jwt_decode(localStorage.getItem('token'));
  const role = decodedToken.role.toLowerCase();
  const course_manage_status = decodedToken.permissions.course_manage=== '0'? false : true;
  const student_manage_status = decodedToken.permissions.student_manage=== '0'? false : true;
  const instructor_manage_status = decodedToken.permissions.insstructor_manage=== '0'? false : true;

  function createAlert () {
    alert('You do not have permission to access this page');
  }
  if (role ===  'coordinator') {
    return (
    <>
    <HeaderLoggedIn role ={role}/>
    <div>
      <div className="hk">
        <section className="hero">
          <h1>Program Coordinator Homepage</h1>
        </section>

        <section className="features">
          <div className="feature">
            
          <a href={course_manage_status === true ? 'course_manage' : undefined} onClick={course_manage_status === false ? createAlert : undefined}>
            <School style={{fontSize:'50px'}}/>
              <div className="feature-title">Course Management</div>
            </a>
            <div className="feature-description">
              Easily create, organize, and manage your courses.
            </div>
          </div>

          <div className="feature">
            <a href={student_manage_status === true ? 'student_manage' : undefined} onClick={student_manage_status === false ? createAlert : undefined}>
            <Group style={{fontSize:'50px'}}/>
              <div className="feature-title">Student Management</div>
            </a>
            <div className="feature-description">
              Effortlessly add and remove students from courses.
            </div>
          </div>

          <div className="feature">
            <a href={instructor_manage_status === true ? 'instructor_manage' : undefined} onClick={instructor_manage_status === false ? createAlert : undefined}>
            <Person style={{fontSize:'50px'}}/>
              <div className="feature-title">Instructor Management</div>
            </a>
            <div className="feature-description">Assign instructors to courses.</div>
          </div>

          <br />

          <div className="feature">
            <a href="chat">
            <Chat style={{fontSize:'50px'}}/>
              <div className="feature-title">Communication</div>
            </a>
            <div className="feature-description">
              Stay connected with your students through integrated chat.
            </div>
          </div>

          <div className="feature">
            <a href="student_reports">
            <Leaderboard style={{fontSize:'50px'}}/>
              <div className="feature-title">Program Evaluation</div>
            </a>
            <div className="feature-description">
              Monitor the student's grades across various courses.
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer/>
    </>
  );
}
else {
  return <Navigate to="/" replace={true} />
};
}

export default ProgramCoordinatorHomepage;
