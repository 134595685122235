import React, { useState, useEffect, useRef } from "react";
import { fetchAnalyticsData } from "../services/api";
import "../css/admin-analytics.css";

function AdminAnalytics() {
    const currentChartRef = useRef(null); // Add this line at the beginning of the AdminAnalytics component

    const [reportType, setReportType] = useState('');
    const [data, setData] = useState([]);
    const chartRef = useRef(null);

    useEffect(() => {
        if (reportType) {
            fetchAnalyticsData(reportType).then((responseData) => {
                setData(responseData);
                renderCharts(responseData); 
            });
        }
    }, [reportType]);

    const renderCharts = (responseData) => {
        if (chartRef.current) {
            let chartType, chartLabels, chartData, backgroundColors, borderColor, chartTitle;
            
            switch (reportType) {
                case "studentPerformance":
                    const gradesPerStudent = {};
                    responseData.forEach(record => {
                        const fullName = `${record.First_Name} ${record.Last_Name}`;
                        if (gradesPerStudent[fullName]) {
                            gradesPerStudent[fullName].push(record.Grade);
                        } else {
                            gradesPerStudent[fullName] = [record.Grade];
                        }
                    });
                    const students = Object.keys(gradesPerStudent);
                    const averageGrades = students.map(student => {
                        const grades = gradesPerStudent[student];
                        return grades.reduce((a, b) => a + b) / grades.length;
                    });
                    chartType = 'bar';
                    chartLabels = students;
                    chartData = averageGrades;
                    backgroundColors = 'blue';
                    borderColor = 'black';
                    chartTitle = 'Average Grades Per Student';
                    break;
                case "courseEffectiveness":
                    chartType = 'pie';
                    chartLabels = responseData.map(item => item.Course_Name);
                    chartData = responseData.map(item => parseFloat(item.Average_Grade));
                    backgroundColors = ['red', 'blue', 'green', 'yellow'];
                    chartTitle = 'Average Grades Per Course';
                    break;
                case "programEvaluation":
                    chartType = 'bar';
                    chartLabels = responseData.map(item => item.Program_Name);
                    chartData = responseData.map(item => parseFloat(item.Average_Grade));
                    backgroundColors = 'green';
                    borderColor = 'black';
                    chartTitle = 'Average Grades Per Program';
                    break;
                default:
                    return;
            }

       // Destroy previous chart instance if it exists
    if (currentChartRef.current) {
        currentChartRef.current.destroy();
    }

    // Create a new chart and store its instance in the ref
    currentChartRef.current = new window.Chart(chartRef.current, {
        type: chartType,
        data: {
            labels: chartLabels,
            datasets: [{
                label: chartTitle,
                data: chartData,
                backgroundColor: backgroundColors,
                borderColor: borderColor,
                borderWidth: 1,
            }],
                },
                options: {
                    responsive: true,  // Enables responsiveness
                    maintainAspectRatio: false  // If you want to stretch in both width and height. Change to true if you want to keep the aspect ratio.
                }
            });
        }
    };

    const renderTable = () => {
        if (!data || data.length === 0) return <p>No data available</p>;

        const headers = Object.keys(data[0]);
        return (
            <div className="table-responsive">
            <table>
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th key={header}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, idx) => (
                        <tr key={idx}>
                            {headers.map((header) => (
                              <td key={header} data-label={header}>{row[header]}</td>

                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        );
    };

    return (
        <div className="admin-analytics-container">
        <select className="select-report" value={reportType} onChange={(e) => setReportType(e.target.value)}>
            <option value="">Select a report type</option>
            <option value="studentPerformance">Individual Student Performance</option>
            <option value="courseEffectiveness">Course Effectiveness</option>
            <option value="programEvaluation">Program Evaluation</option>
        </select>
        <div className="canvas-container">
    <canvas ref={chartRef}></canvas>
</div>

        {renderTable()}
    </div>
    );
}

export default AdminAnalytics;