import React, { useState, useEffect } from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import { fetchExams, getPerformanceMetrics, fetchFeedbackAndGrades } from '../../services/api';
import './reports.css';
import GradesBarChart from './GradesBarChart';
import reportsImg from '../../images/student_report.png'
import jwt_decode from 'jwt-decode';

const Reports = () => {
    document.title = "Reports";
    const [exams, setExams] = useState([]);
    const [selectedExam, setSelectedExam] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [gradesData, setGradesData] = useState([]);
    const [maxScore, setMaxScore] = useState(100);


    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const { course_id } = useParams();


    useEffect(() => {
        fetchExams(course_id).then(data => {
            setExams(data);
            console.log(data)
            setSelectedExam(data[0]?.Exam_ID); // Automatically select the first exam
            setMaxScore(data[0]?.max_points)
            setLoading(false);
        }).catch(err => {
            setError(err.message);
            setLoading(false);
        });
    }, [course_id]);

    useEffect(() => {
        if (selectedExam) {
            getPerformanceMetrics(course_id, selectedExam).then(data => {
                setReportData([
                    { title: 'High Score', value: data.HighScore },
                    { title: 'Low Score', value: data.LowScore },
                    { title: 'Average Score', value: data.MeanScore },
                    { title: 'Highest Scoring Student', value: data.HighestScoringStudent }
                ]);
                setMaxScore(exams.find(exam => exam.Exam_ID === selectedExam)?.max_points || 100)
            }).catch(err => {
                setError(err.message);
            });

            // Fetch grades for the bar chart
            fetchFeedbackAndGrades(course_id, selectedExam).then(grades => {
                setGradesData(grades);
            }).catch(err => {
                setError(err.message);
            });
        }
    }, [selectedExam, course_id]);

    const handleExamChange = (e) => {
        setSelectedExam(e.target.value);
    };

    if (role === 'instructor') {

        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error: {error}</div>;
        return (
            <>
                <HeaderLoggedIn role={role} />
                <div className="reportsContainer">
                    <div className="reports-main-content">
                        <div className="reports-nav">
                            <Link to={`/instructor/coursehome/${course_id}`} >Home</Link>
                            <Link to={`/instructor/announcments/${course_id}`} >Announcements</Link>
                            <Link to={`/instructor/syllabus/${course_id}`}>Syllabus</Link>
                            <Link to={`/instructor/modules/${course_id}`} >Modules</Link>
                            <Link to={`/instructor/exams/${course_id}`} >Exams</Link>
                            <Link to={`/instructor/grades/${course_id}`}>Grades</Link>
                            <Link to={`/instructor/people/${course_id}`} >People</Link>
                            <Link to={`/instructor/reports/${course_id}`} className="reports-selected">Reports</Link>
                        </div>
                        <div className="reports-content">
                            <div className="report-selection">
                                <label htmlFor="reportType">Select Report:</label>
                                <select id="reportType" onChange={handleExamChange}>
                                    { exams.length > 0 && exams.map((exam) => (
                                        <option key={exam.Exam_ID} value={exam.Exam_ID}>
                                            {exam.Exam_Name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="metrics">
                                {reportData.length > 0 &&reportData.map((metric, index) => (
                                    <div className="metric" key={index}>
                                        <div className="metric-title">{metric.title}</div>
                                        <div>{metric.value}</div>
                                    </div>
                                ))}
                            </div>
                            <div className="chart-representation">
                                {/* Here we use the GradesBarChart component and pass the grades data */}
                                {gradesData.length > 0 && <GradesBarChart gradesData={gradesData} maxScore={maxScore} />}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    else {
        return <Navigate to="/" replace={true} />
    };
};

export default Reports;
