import React, { useState, useEffect } from 'react';
import './QADashboard.css';
import { Navigate } from 'react-router-dom';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import jwt_decode from 'jwt-decode';
import { fetchRecommendations, createRecommendation, updateRecommendation, deleteRecommendation, fetchAnalysis } from '../../services/api';

const QADashboard = () => {
  document.title = "Quality Assurance Officer";
  const decodedToken = jwt_decode(localStorage.getItem('token'));
  const role = decodedToken.role.toLowerCase();
  const view_feedback_status = decodedToken.permissions.view_feedback=== '0'? false : true;
  const add_recommendations_status = decodedToken.permissions.add_recommendations=== '0'? false : true;

  const [recommendationsForAssessments, setRecommendationsForAssessments] = useState([]);
  const [recommendationsForTechingMethods, setRecommendationsForTechingMethods] = useState([]);
  const [recommendationsForProgramEffectiveness, setRecommendationsForProgramEffectiveness] = useState([]);
  const [newRecommendation, setNewRecommendation] = useState({ type: 'Teaching Methods', recommendation: '' });
  const [selectedRecommendation, setSelectedRecommendation] = useState({ id: '', type: '', recommendation: '' });
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);
  const [analysis, setAnalysis] = useState([]);

  useEffect(() => {
    fetchAllRecommendations();
    fetchStudentAnalysis();
  }, []);

  const fetchStudentAnalysis = () => {
    fetchAnalysis()
      .then((data) => {
        setAnalysis(data);
      })
      .catch((error) => console.error('Error fetching recommendations: ', error));
  }
  const fetchAllRecommendations = () => {
    fetchRecommendations()
      .then((data) => {
        const assessments = data.filter((recommendation) => recommendation.type === 'Assessments');
        const teachingMethods = data.filter((recommendation) => recommendation.type === 'Teaching Methods');
        const programEffectiveness = data.filter((recommendation) => recommendation.type === 'Program Effectiveness');

        setRecommendationsForAssessments(assessments);
        setRecommendationsForTechingMethods(teachingMethods);
        setRecommendationsForProgramEffectiveness(programEffectiveness);
      })
      .catch((error) => console.error('Error fetching recommendations: ', error));
  }
  const handleUpdate = (policy) => {
    setSelectedRecommendation(policy);
    setIsUpdatePopupOpen(true);
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this recommendation?');
    if (confirmDelete) {
      deleteRecommendation(id)
        .then(() => {
          fetchAllRecommendations();
        })
        .catch((error) => console.error('Error deleting recommendation: ', error));
    }
  };
  const closeAddPopup = () => {
    setNewRecommendation({ type: 'Teaching Methods', recommendation: '' });
    setIsAddPopupOpen(false);
  };

  const handleAdd = () => {
    if(!add_recommendations_status){
      alert('You do not have permission to add recommendations');
    }
    else if (newRecommendation.recommendation === '') {
      alert('Recommendation cannot be empty');
    }
    else {
      createRecommendation(newRecommendation)
        .then(() => {
          fetchAllRecommendations();
          closeAddPopup();
        })
        .catch((error) => console.error('Error creating recommendation: ', error));

    }
  };
  const handleUpdateSubmit = () => {
    if (selectedRecommendation.recommendation === '') {
      alert('Recommendation cannot be empty');
    }
    else {
      updateRecommendation(selectedRecommendation)
        .then(() => {
          fetchAllRecommendations();
          setIsUpdatePopupOpen(false)
        })
        .catch((error) => console.error('Error creating recommendation: ', error));

    }
  };
  if (role === 'qa') {
    return (
      <>
        <HeaderLoggedIn role={role} />
        <div className="content-containerchat">
          <div className="left-panelchat">
            <a className="menuButton" href="/QAPolicies">Quality assurance processes and policies</a>
            <a className="menuButton" href="/program-objectives">Program Objectives</a>
            {view_feedback_status &&
              <a className="menuButton" href="/feedback">Program Feedback</a>
            }
            <a className="menuButton" href="/student_reports">Student Performance Analysis</a>
          </div>
          <div className="right-containerchat">
            <h1>Quality Assurance Officer</h1>

            <div className="section">
              <h2>Reports</h2>
              <div className="reports">

                <table>
                  <thead>
                    <tr>
                      <th>Course Name</th>
                      <th>Exam Name</th>
                      <th>Exam Date</th>
                      <th>Grade Average out of 100</th>
                    </tr>
                  </thead>
                  <tbody>
                    {analysis.map((analysis) => (
                      <tr key={analysis.Exam_Name}>
                        <td>{analysis.Course_Name}</td>
                        <td>{analysis.Exam_Name}</td>
                        <td>{analysis.Exam_Date}</td>
                        <td>{analysis.Average_Grade}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
              <div className="graph">
                <a href="/student_reports" className="analytics-button">View Analysis Reports</a>
              </div>
            </div>


            <div className="section">

              <h2>Teaching Methods</h2>
              <div className="reports">
                <table>
                  <thead>
                    <tr>
                      <th>Recommendation</th>
                      <th>Update</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recommendationsForTechingMethods.map((policy) => (
                      <tr key={policy.id}>
                        <td>{policy.recommendation}</td>
                        <td>
                          <button className="btn-update" onClick={() => handleUpdate(policy)}>Update</button>
                        </td>
                        <td><button className="btn-delete" onClick={() => handleDelete(policy.id)}>Delete</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
            <div className="section">

              <h2>Assessments</h2>
              <div className="reports">

                <table>
                  <thead>
                    <tr>
                      <th>Recommendation</th>
                      <th>Update</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recommendationsForAssessments.map((policy) => (
                      <tr key={policy.id}>
                        <td>{policy.recommendation}</td>
                        <td>
                          <button className="btn-update" onClick={() => handleUpdate(policy)}>Update</button>
                        </td>
                        <td><button className="btn-delete" onClick={() => handleDelete(policy.id)}>Delete</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="section">
              <h2>Program Effectiveness</h2>
              <div className="reports">

                <table>
                  <thead>
                    <tr>
                      <th>Recommendation</th>
                      <th>Update</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recommendationsForProgramEffectiveness.map((policy) => (
                      <tr key={policy.id}>
                        <td>{policy.recommendation}</td>
                        <td>
                          <button className="btn-update" onClick={() => handleUpdate(policy)}>Update</button>
                        </td>
                        <td><button className="btn-delete" onClick={() => handleDelete(policy.id)}>Delete</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="section">
              <div className="reports">
                <button className="btn-add" onClick={() => setIsAddPopupOpen(true)}>Click here to add new recommendation</button>
              </div>
            </div>
          </div>
        </div>
        {isAddPopupOpen && (
          <div className={`update-policy-popup ${isAddPopupOpen ? 'visible' : ''}`}>
            <div className="popup-content">
              <h2>Add Recommendation</h2>
              <label>
                Recommendation Type: <select
                  value={newRecommendation.type || 'Teaching Methods'}
                  onChange={(e) => setNewRecommendation({ ...newRecommendation, type: e.target.value })}
                >
                  <option value="Teaching Methods">Teaching Methods</option>
                  <option value="Assessments">Assessments</option>
                  <option value="Program Effectiveness">Program Effectiveness</option>
                </select>
              </label>
              <label>
                Recommendation Description:
                <textarea
                  value={newRecommendation.recommendation}
                  onChange={(e) => setNewRecommendation({ ...newRecommendation, recommendation: e.target.value })}
                />
              </label>
              <div className="popup-buttons">
                <button onClick={closeAddPopup}>Cancel</button>
                <button onClick={handleAdd} >Submit</button>
              </div>
            </div>
          </div>
        )}
        {isUpdatePopupOpen && (
          <div className={`update-policy-popup ${isUpdatePopupOpen ? 'visible' : ''}`}>
            <div className="popup-content">
              <h2>Update Recommendation</h2>
              <label>
                Recommendation Type: <select
                  value={selectedRecommendation.type || 'Teaching Methods'}
                  onChange={(e) => setSelectedRecommendation({ ...selectedRecommendation, type: e.target.value })}
                >
                  <option value="Teaching Methods">Teaching Methods</option>
                  <option value="Assessments">Assessments</option>
                  <option value="Program Effectiveness">Program Effectiveness</option>
                </select>
              </label>
              <label>
                Recommendation Description:
                <textarea
                  value={selectedRecommendation.recommendation}
                  onChange={(e) => setSelectedRecommendation({ ...selectedRecommendation, recommendation: e.target.value })}
                />
              </label>
              <div className="popup-buttons">
                <button onClick={() => setIsUpdatePopupOpen(false)}>Cancel</button>
                <button onClick={handleUpdateSubmit} >Submit</button>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
  else {
    return <Navigate to="/" replace={true} />
  };
};

export default QADashboard;
