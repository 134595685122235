import React,{useState, useEffect} from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import CoursesCommon from '../components/CoursesCommon';
import '../css/coursescommon.css';
import { validateToken } from '../utils/authUtils';
import HeaderLoggedIn from '../components/headerLoggedIn';
import jwt_decode from 'jwt-decode';



const Courses = () => {
    document.title = "Courses";
    const token = localStorage.getItem('token');
    var role = '';
    if (token) {
        role = jwt_decode(token).role.toLowerCase();
    }
    return (
        <>
            {validateToken() ? <HeaderLoggedIn role={role}/> : <Header />}
            
            <div className="main-content-courses">
       
                    <CoursesCommon />
            
                {/* Put the footer at absolute bottom */}
                <div className="footer-push">
                <Footer />
                </div>
            </div>
        </>
    );
}

export default Courses;
