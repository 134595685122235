import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import '../css/header.css';
import { fetchCourses, createCourse, removeCourse, editCourse} from '../services/api';
import HeaderLoggedIn from '../components/headerLoggedIn';
import Footer from '../components/footer';
import jwt_decode from 'jwt-decode';

function ManageCourses() {
  document.title = "Course Manage";
  const decodedToken = jwt_decode(localStorage.getItem('token'));
  const role = decodedToken.role.toLowerCase();
  const view_course_manage_status = decodedToken.permissions.course_manage === '0' ? false : true;
  const [courses, setCourses] = useState([]);
  const [newCourseName, setNewCourseName] = useState(''); // State for the new course name
  const [newCourseDescription, setNewCourseDescription] = useState(''); // State for the new course description
  const [editingCourseId, setEditingCourseId] = useState(null);
  const [editedCourseName, setEditedCourseName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  
  // Function to handle editing a course
  const handleEditCourse = () => {
    // Create an object with the updated course data

    // Call the editCourse function to send the data to your PHP program
    editCourse({editingCourseId, editedCourseName})
      .then((response) => {
        // Handle the response, e.g., display a success message or handle errors
        console.log(response);
        alert("Successfully updated the course name.")
        fetchAllCourses();
      })
      .catch((error) => console.log("ee"+error));

    setEditingCourseId(-1);
  };

  // Function to handle removing a course
  const handleRemoveCourse = (courseId) => {
    // Implement the logic for removing a course

    // Call the removeCourse function to remove the course from the server
    removeCourse(courseId)
      .then((response) => {
        // Handle the response (e.g., display a success message or handle errors)
        alert("Course Deleted Successfully");
        fetchAllCourses();
        // Optionally, update the list of courses after removal
        // Refetch the courses or update the state accordingly
      })
      .catch((error) => alert(error));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Call the createCourse function to send the data to your PHP program
    // alert(JSON.stringify({courseName: newCourseName , courseDescription: newCourseDescription}));
    createCourse({newCourseName , newCourseDescription})
      .then((response) => {
        // Handle the response, e.g., display a success message or handle errors
        alert("Course Created Successfully");
        fetchAllCourses();
        // Clear the input fields or perform any other necessary actions
        setNewCourseName('');
        setNewCourseDescription('');
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchAllCourses();
  }, []);

  function fetchAllCourses() {
        // Fetch existing courses when the component mounts
        fetchCourses()
        .then((data) => setCourses(data))
        .catch((error) => console.error(error));
  }


  // Filter the courses based on the search query
  const filteredCourses = courses.filter((course) => {
    return course.Course_Name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  // Map through the filtered courses
  const courseCards = filteredCourses.map((course) => (
    <div key={course.Course_ID} className="course-card">
  <div>
    <span className="course-name">
      {course.Course_ID === editingCourseId ? (
        <input
          type="text"
          value={editedCourseName}
          onChange={(e) => setEditedCourseName(e.target.value)}
          style={{ fontSize: "18px", width: "auto" }}
        />
      ) : (
        course.Course_Name
      )}
    </span>
  </div>
  <div className="course-actions">
    {course.Course_ID === editingCourseId ? (
      <button onClick={handleEditCourse}>Save</button>
    ) : (
      <button
        onClick={() => {
          // Start editing this course
          setEditingCourseId(course.Course_ID);
          setEditedCourseName(course.Course_Name);
        }}>
        Edit
      </button>
    )}
    <button type="button" name="Remove" onClick={() => handleRemoveCourse(course.Course_ID)}>
      Remove
    </button>
  </div>
</div>
    
  ));


  if ((role === "coordinator" && view_course_manage_status) || (role === "instructor" && view_course_manage_status) || role == "admin") {
  return (
    <>
    <HeaderLoggedIn role ={role}/>
    <div>
      <div className="hk">
        <section className="hero">
          <h1>Manage Courses</h1>
        </section>

        <div className="c_container">
          <div className="create-course-section">
            <h2>Create a New Course</h2>
            <form className="create-course-form" onSubmit={handleSubmit} method='post'>
                <label htmlFor="courseName">Course Name:</label>
                <input
                  type="text"
                  id="courseName"
                  name="courseName"
                  required
                  value={newCourseName}
                  onChange={(e) => setNewCourseName(e.target.value)}
                />

                <label htmlFor="courseDescription">Course Description:</label>
                <textarea
                  id="courseDescription"
                  name="courseDescription"
                  rows="4"
                  required
                  value={newCourseDescription}
                  onChange={(e) => setNewCourseDescription(e.target.value)}
                />

                <input type="submit" value="Create Course" />
              </form>
          </div>
        </div>

        <div className="c_container">
          <h2>Edit/Remove Existing Courses</h2>
          {/* <div className="course-card">
            <div>
              <span className="course-name">Web Data Management</span>
            </div>
            <div className="course-actions">
              <button type="button" name="Edit">Edit</button>
              <button type="button" name="Remove">Remove</button>
            </div>
          </div>
          <div className="course-card">
            <div>
              <span className="course-name">Cloud Computing</span>
            </div>
            <div className="course-actions">
              <button type="button" name="Edit">Edit</button>
              <button type="button" name="Remove">Remove</button>
            </div>
            </div> */}
            <label htmlFor="search" style={{ marginRight: "10px", fontSize: "22px" }}>
              Search:
            </label>
            <input
              type="text"
              placeholder="Search courses"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                width: "30%",
                height: "40px",
                fontSize: "16px",
                margin: "0 auto",
                marginBottom: "20px",
              }}
            />
            {courseCards}
            {/* {courses.map((course) => (
                <div key={course.Course_ID} className="course-card">
                  <div>
                    <span className="course-name"> 
                    {course.Course_ID === editingCourseId ? (
                      <input
                        type="text"
                        value={editedCourseName}
                        onChange={(e) => setEditedCourseName(e.target.value)}
                        style={{ fontSize: "18px", width: "auto" }}
                      />
                    ) : (
                      course.Course_Name
                    )}
                    </span>
                  </div>
                  <div className="course-actions">
                  {course.Course_ID === editingCourseId ? (
                    <button onClick={handleEditCourse}>Save</button>
                    ) : (
                    <button
                      onClick={() => {
                        // Start editing this course
                        setEditingCourseId(course.Course_ID);
                        setEditedCourseName(course.Course_Name);
                      }}>
                        Edit 
                    </button>
                  )}
                    <button type="button" name="Remove" onClick={() => handleRemoveCourse(course.Course_ID)}>
                    Remove
                    </button>
                  </div>
                </div>
              ))} */}
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
  }
  else {
    return <Navigate to="/" replace={true} />
  };
}



export default ManageCourses;
