import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import '../css/style.css';
import '../css/header.css';
import {fetchCourses,fetchAssignedCourses, removeInstructor, assignInstructor} from '../services/api';
import HeaderLoggedIn from '../components/headerLoggedIn';
import Footer from '../components/footer';
import jwt_decode from 'jwt-decode';

function ManageInstructors() {
  document.title = "Instructor Management";
  const decodedToken = jwt_decode(localStorage.getItem('token'));
  const role = decodedToken.role.toLowerCase();
  const instructor_manage_status = decodedToken.permissions.insstructor_manage=== '0'? false : true;
  const [availableCourses, setAvailableCourses] = useState([]); // State for available courses
  const [assignedCourses, setAssignedCourses] = useState([]); // State for assigned courses
  const [instructorId, setInstructorId] = useState(''); // State for the selected course
  const [instructorId1, setInstructorId1] = useState(''); // State for the selected course
  
  useEffect(() => {
    // Fetch available courses when the component mounts
    fetchCourses()
      .then((data) => setAvailableCourses(data))
      .catch((error) => console.error(error));
  }, []);

  const fetch_Courses = () => {
    // implement logic to fetch the assigned courses for the instructor
    fetchAssignedCourses(instructorId1)
      .then((data) => {
      setAssignedCourses(data);
      })
      .catch((error) => alert(error));
  }
  // function to remove an instructor from a course
  const handleRemoveInstructor = (e) => {
    e.preventDefault();
    const x = document.getElementById('instructorName1').value;
    const y = document.getElementById('course1').value;
    if (!y) {
      alert('Please select a course.');
      return;
    }
    removeInstructor({x,y})
    .then((response) => {
      // Handle the response, e.g., display a success message or handle errors
      alert(response);
    })
    .catch((error) => alert(error));
  }

  // function to assign an instructor to a course
  const handleAssignInstructor = (e) => {
    e.preventDefault();
    const x = document.getElementById('instructorName').value;
    const y = document.getElementById('course').value;
    if (!x || !y) {
      alert('Please enter both instructor ID and course ID.');
      return;
    }
    assignInstructor({x , y})
    .then((response) => {
      // Handle the response, e.g., display a success message or handle errors
      alert(response);
    })
    .catch((error) => alert(error));
  }

  if ((role === 'coordinator' &&instructor_manage_status) || role === 'admin') {
    return (
    <>
    <HeaderLoggedIn role ={role}/>
    <div>
      <div className="hk">
        <section className="hero">
          <h1>Manage Instructors</h1>
        </section>

        <div className="c_container">
          <h2>Assign Instructor to Course</h2>
          <form action="assign_instructor.php" method="post" className="create-course-form" onSubmit={handleAssignInstructor}>
            <label htmlFor="instructorName">Instructor ID:</label>
            <input type="text" id="instructorName" name="instructorName" required onChange={(e) => {
              setInstructorId(e.target.value); 
              document.getElementById('course').value='';
            }}/>
            <br /><br />

            <label htmlFor="course">Select Course:</label>
            <select id="course" name="course">
            <option value="" disabled hidden>
                    Select course
                  </option>
                  {availableCourses.map((course) => (
                    <option key={course.Course_ID} value={course.Course_ID}>
                      {course.Course_Name}
                    </option>
                  ))}
            </select><br /><br />

            <input type="submit" value="Assign" />
          </form>
        </div>
        <br /><br />

        <div className="c_container">
          <h2>Remove Instructor from Course</h2>
          <form action="remove_instructor.php" method="post" className="create-course-form" onSubmit={handleRemoveInstructor}>
          <label htmlFor="instructorName">Instructor ID:</label>
            <input type="text" id="instructorName1" name="instructorName" onChange={(e) => {
              setInstructorId1(e.target.value); 
              document.getElementById('course1').value='';
            }} required />
            <br /><br />
            <label htmlFor="course">Select Course:</label>
            <select id="course1" name="course1" onClick={fetch_Courses}>
            <option value="" disabled hidden>
                    Select course
                  </option>
                  {assignedCourses.map((course) => (
                    <option key={course.Course_ID} value={course.Course_ID}>
                      {course.Course_Name}
                    </option>
                  ))}
            </select>
            <br /><br />
            <input type="submit" value="Remove" />
          </form>
        </div>
        <br /><br />
      </div>
    </div>
    <Footer/>
    </>
  );
}
else {
  return <Navigate to="/" replace={true} />
};
}

export default ManageInstructors;
