import React, { useState } from 'react';
import '../css/manage-permissions.css';
import { updatePermissions } from '../services/api';

const PermissionsForm = ({ role, goBack, data }) => {
    const permissionsForRole = data.filter(item => item.role === role);
    const [permissions, setPermissions] = useState({});

    const isPermissionGranted = (permissionType) => {
        const value = permissionsForRole.some(item => item.permission_type === permissionType && item.status === '1');
        return value;
    };

    const handlePermissionChange = (event) => {
        const { name, checked } = event.target;
        setPermissions(prevPermissions => ({
            ...prevPermissions,
            [name]: checked ? 1 : 0,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Convert permissions object to an array of objects with permission_type and status
        const permissionsArray = Object.entries(permissions).map(([permission_type, status]) => ({
            role,
            permission_type,
            status,
        }));
        // Send a PUT request to your API with the permissions data
        updatePermissions( permissionsArray)
            .then(response => {
                // Handle the response as needed
                alert('Permissions updated successfully!');
            })
            .catch(error => {
                // Handle errors
                console.error('Error updating permissions:', error);
            });
    };

    let formContent;

    switch (role) {
        case 'student':
            formContent = (
                <>
                    <h1>Manage Permissions for Student</h1>
                    <form id="permissionsForm">
                        <h2>General Permissions</h2>
                        <label><input type="checkbox" name="view_profile" defaultChecked={isPermissionGranted('view_profile')} onChange={handlePermissionChange}/> View Profile</label>
                        <label><input type="checkbox" name="edit_profile" defaultChecked={isPermissionGranted('edit_profile')} onChange={handlePermissionChange} /> Edit Profile</label>
                        <label><input type="checkbox" name="discussion_participation" defaultChecked={isPermissionGranted('discussion_participation')} onChange={handlePermissionChange}/> Discussion Participation</label>

                    </form>
                </>
            );
            break;

        case 'instructor':
            formContent = (
                <>
                    <h1>Manage Permissions for Instructor</h1>
                    <form>
                        <h2>General Permissions</h2>
                        <label><input type="checkbox" name="course_manage" defaultChecked={isPermissionGranted('course_manage')} onChange={handlePermissionChange} /> Manage Courses</label>
                        <label><input type="checkbox" name="edit_profile" defaultChecked={isPermissionGranted('edit_profile')} onChange={handlePermissionChange}/> Edit Own Profile</label>

                    </form>
                </>
            );
            break;

        case 'qa':
            formContent = (
                <>
                    <h1>Manage Permissions for QA Officer</h1>
                    <form>
                        <h2>General Permissions</h2>
                        <label><input type="checkbox" name="view_feedback" defaultChecked={isPermissionGranted('view_feedback')} onChange={handlePermissionChange}/> View Feedback</label>
                        <label><input type="checkbox" name="create_policies" defaultChecked={isPermissionGranted('create_policies')} onChange={handlePermissionChange}/> Create Policies</label>
                        <label><input type="checkbox" name="add_recommendations" defaultChecked={isPermissionGranted('add_recommendations')} onChange={handlePermissionChange} /> Add new Recommendations</label>


                    </form>
                </>
            );
            break;

        case 'coordinator':
            formContent = (
                <>
                    <h1>Manage Permissions for Program Coordinator</h1>
                    <form>
                        <h2>General Permissions</h2>
                        <label><input type="checkbox" name="course_manage" defaultChecked={isPermissionGranted('course_manage')} onChange={handlePermissionChange}/> Manage Courses</label>
                        <label><input type="checkbox" name="student_manage" defaultChecked={isPermissionGranted('student_manage')} onChange={handlePermissionChange}/> Manage Student</label>
                        <label><input type="checkbox" name="insstructor_manage" defaultChecked={isPermissionGranted('insstructor_manage')} onChange={handlePermissionChange}/> Manage Instructor</label>

                    </form>
                </>
            );
            break;

        default:
            formContent = null;
    }

    return (
        <div className="permissions-page">
            {formContent}
            <button onClick={goBack}>Back</button>
            <button onClick={handleSubmit}>Save Permissions</button>
        </div>
    );
};

export default PermissionsForm;
