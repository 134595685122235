import React from 'react';
import '../css/style.css';
import '../css/contact.css';
import '../css/header.css';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import HeaderLoggedIn from '../components/headerLoggedIn';
import Footer from '../components/footer';


function ReportsAndAnalytics() {
  document.title = "Program Evaluation";
  const decodedToken = jwt_decode(localStorage.getItem('token'));
  const role = decodedToken.role.toLowerCase();

  // page not used for now
  return <Navigate to="/coordinator_dashboard" replace={true} />

  if (role === 'qa' || role === 'admin' || role === 'coordinator') {
    return (
    <>
    <HeaderLoggedIn role ={role}/>
      <div className="hk">
        <section className="hero">
          <h1>Program Evaluation</h1>
        </section>
        
        <section className="content">
          <a href="student_reports">
            <div className="report-card">
              <div className="report-title">
                Student Reports
              </div>
              <div className="report-description">
                View and analyze student grades for the current semester.
              </div>
            </div>
          </a>
  
          {/* <a href="attendance_analytics">
            <div className="report-card">
              <div className="report-title">
                Attendance Analytics
              </div>
              <div className="report-description">
                Monitor student attendance and class participation.
              </div>
            </div>
          </a> */}
        </section>
      </div>
      <Footer/>
    </>
    );
  }
else {
  return <Navigate to="/" replace={true} />
};
}
  
  export default ReportsAndAnalytics;