import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import './syllabus.css';
import { Navigate } from 'react-router-dom';
import { fetchSyllabus, saveSyllabus } from '../../services/api';
import jwt_decode from 'jwt-decode';

const Syllabus = () => {
    document.title = "Syllabus";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const { course_id } = useParams();
    const [syllabus, setSyllabus] = useState("");
    const [banner, setBanner] = useState({ message: '', type: '' });

    const showBanner = (message, type) => {
        setBanner({ message, type });
        // Automatically hide the banner after 3 seconds
        setTimeout(() => setBanner({ message: '', type: '' }), 3000);
    };

    useEffect(() => {

        async function fetchCourseSyllabus() {
            try {
                const data = await fetchSyllabus(course_id);
                setSyllabus(data.Syllabus);
            } catch (error) {
                console.error("Error fetching syllabus:", error);
            }
        }
        fetchCourseSyllabus();
    }, []);

    async function handleSaveSyllabus() {
        try {
            const response = await saveSyllabus(course_id, syllabus);
            if (response?.message) {
                showBanner(response.message, 'success');
            } else {
                showBanner(response.error || 'Failed to save syllabus', 'failure');
            }
        } catch (error) {
            showBanner(error || '"An error occurred while saving the syllabus. Please try again later.', 'failure');
            console.error("Error saving syllabus:", error);
        }
    }



    if (role === 'instructor') {
        return (
            <>
                <>
                    {banner.message && (
                        <div className={`banner banner-${banner.type}`}>
                            {banner.message}
                        </div>
                    )}
                    {/* Rest of your component */}
                </>
                <HeaderLoggedIn role={role} />
                <div className="syllabusContainer">
                    <div className="syllabus-main-content">
                        <div className="syllabus-nav">
                            <Link to={`/instructor/coursehome/${course_id}`} >Home</Link>
                            <Link to={`/instructor/announcments/${course_id}`} >Announcements</Link>
                            <Link to={`/instructor/syllabus/${course_id}`} className="syllabus-selected">Syllabus</Link>
                            <Link to={`/instructor/modules/${course_id}`} >Modules</Link>
                            <Link to={`/instructor/exams/${course_id}`} >Exams</Link>
                            <Link to={`/instructor/grades/${course_id}`}>Grades</Link>
                            <Link to={`/instructor/people/${course_id}`} >People</Link>
                            <Link to={`/instructor/reports/${course_id}`}>Reports</Link>
                        </div>
                        <div className="syllabus-content">

                            <div className="syllabus-section">
                                <h2 className="editor-header">
                                    Course Outline
                                </h2>

                                <textarea
                                    className="syllabus-textarea"
                                    value={!syllabus ? '' : syllabus.replace(/\\n/g, '\n')}
                                    onChange={e => setSyllabus(e.target.value)}
                                />
                            </div>
                            <button className="save-syllabus-btn" onClick={handleSaveSyllabus}>Save Syllabus</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    else {
        return <Navigate to="/" replace={true} />
    };
};

export default Syllabus;
