import React from 'react';
import '../css/home.css';
import Header from '../components/header';
import Footer from '../components/footer';
import imgPOS from '../images/program-objectives.png';
import { validateToken } from '../utils/authUtils';
import HeaderLoggedIn from '../components/headerLoggedIn';
import jwt_decode from 'jwt-decode';

const Home = () => {
    document.title = "Home";
    const token = localStorage.getItem('token');
    var role = '';
    if (token) {
        role = jwt_decode(token).role.toLowerCase();
    }
    
    return (
        <>
        {validateToken() ? <HeaderLoggedIn role={role}/> : <Header />}
            <div className="content-containerHome">
    <h2 className="home">Program Overview</h2>
    <p>Our academic program is designed to provide students with a comprehensive education and prepare them for success in Computer Science field. We offer a wide range of courses and opportunities for students to explore their interests and develop their skills.</p>

    <h2 className="home">Program Objectives</h2>
    <ul>
      <img  className = "homeimg" src={imgPOS} alt="Program Overview Image"/>
      <a className="link" href="/program-objectives">
        <p className = "pos">Click here to get more details on the program objectives.</p></a>
        <li>Providing a strong foundation of knowledge in the chosen field.</li>
        <li>Developing critical thinking and problem-solving skills.</li>
        <li>Fostering creativity and innovation.</li>
        <li>Promoting collaboration and teamwork.</li>
        
    </ul>

    <div className="sectionHome">
        <h2 className="home">Performance Measurement and Assessment</h2>
        <div className="homeimage-container">
          <img className = "homeimg" src="https://washburnreview.org/wp-content/uploads/2023/02/grading-teachers.png" alt="Performance Measurement Graph"/>
    </div>
        <p>Performance measurement and assessment play a crucial role in our academic program. We believe that evaluating and tracking student performance is essential for several reasons:</p>
        <ul>
            <li>Helps identify areas of strength and areas that need improvement.</li>
            <li>Enables personalized feedback and support for students.</li>
            <li>Facilitates the identification of effective teaching methods and curriculum adjustments.</li>
            <li>Allows for the recognition and celebration of student achievements.</li>
            <li>Aids in the overall program evaluation and continuous improvement.</li>
        </ul>
        <p>Through performance measurement and assessment, we strive to create a supportive and engaging learning environment that maximizes student success and growth.</p>
        
        </div>
        
</div>
            <Footer />
        </>
    );
}

export default Home;
