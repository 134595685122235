// import React from 'react';
// import '../css/style.css';
// import logo from '../images/logo4.png';

// function Header() {
//     return (
//         <header className="headerContainer">
//             <div className="headerLogo">
//                 <a href="/" target="_top">
//                     <img src={logo} alt="Logo" className="logoImage" />
//                 </a>
//             </div>
//             <ul className="menu">
//                 <li><a href="/" target="_top">Home</a></li>
//                 <li><a href="/courses" target="_top">Courses</a></li>
//                 <li><a href="/auth/login" target="_top">Sign-In</a></li>
//                 <li><a href="/auth/register" target="_top">Register</a></li>
//             </ul>
//         </header>
//     );
// }

// export default Header;

import React, {useState} from 'react';
import '../css/style.css';
import logo from '../images/logo4.png';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    return (
        <header className="headerContainer">
            <div className="headerLogo">
                <a href="/" target="_top">
                    <img src={logo} alt="Logo" className="logoImage" />
                </a>
            </div>
            <button className="hamburger" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
                <li><a href="/" target="_top">Home</a></li>
                <li><a href="/courses" target="_top">Courses</a></li>
                <li><a href="/auth/login" target="_top">Sign-In</a></li>
                <li><a href="/auth/register" target="_top">Register</a></li>
            </ul>
        </header>
    );
}

export default Header;
