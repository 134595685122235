import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchExams, fetchFeedbackAndGrades, saveGradesAndFeedback } from '../../services/api';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import './grades.css';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';


const Grades = () => {
    document.title = "Grades";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const { course_id } = useParams();

    const [feedback, setFeedback] = useState("");
    const [showModal, setShowModal] = useState(false);

    const [exams, setExams] = useState([]);
    const [selectedExam, setSelectedExam] = useState(null);
    const [maxPoints, setMaxPoints] = useState(null);
    const [studentsData, setStudentsData] = useState([]);

    const [editingGrade, setEditingGrade] = useState(null);
    const [editedGrade, setEditedGrade] = useState("");

    const [currentStudent, setCurrentStudent] = useState(null);
    const [banner, setBanner] = useState({ message: '', type: '' }); // type can be 'success' or 'failure'


    const openFeedbackModal = () => {
        setShowModal(true);
    };

    const closeFeedbackModal = () => {
        setShowModal(false);
    };

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };

    const showBanner = (message, type) => {
        setBanner({ message, type });
        // Automatically hide the banner after 3 seconds
        setTimeout(() => setBanner({ message: '', type: '' }), 3000);
    };

    const handleSaveChanges = async () => {
        if (currentStudent) {
            const studentId = currentStudent.Student_ID;
            const examId = selectedExam; // Assuming selectedExam holds the Exam_ID
            try {
                if(!currentStudent.Grade)
                    {
                        currentStudent.Grade = 0;
                    }
                if(!feedback)
                    {
                        feedback = "";
                    }
                const response = await saveGradesAndFeedback(studentId, examId, currentStudent.Grade, feedback);
                if (response.message) {
                    // Update the feedback in the local state to reflect the change
                    setStudentsData(currentStudents => currentStudents.map(s => {
                        if (s.Student_ID === studentId) {
                            return { ...s, Instructor_Feedback: feedback };
                        }
                        return s;
                    }));
                    showBanner(response.message, 'success');
                } else {
                    showBanner(response.error || 'Failed to save feedback', 'failure');
                }
            } catch (error) {
                console.error("Error saving grades and feedback for student:", studentId, error);
                showBanner(error.message || 'An error occurred while saving', 'failure');
            }
        }
        closeFeedbackModal();
    };

    const handleOpenFeedbackModal = (student) => {
        setFeedback(student.Instructor_Feedback || "");
        setCurrentStudent(student);
        setShowModal(true);
    };



    useEffect(() => {
        async function loadExams() {
            try {
                const examsData = await fetchExams(course_id);
                setExams(examsData);
                if (examsData.length > 0) {
                    setSelectedExam(examsData[0].Exam_ID);
                    // Fetch grades immediately after setting the selected exam
                    setMaxPoints(examsData[0].max_points);
                    fetchGradesForSelectedExam(examsData[0].Exam_ID);
                }
            } catch (error) {
                console.error("Failed to fetch exams:", error);
            }
        }

        async function fetchGradesForSelectedExam(examID) {
            try {
                const data = await fetchFeedbackAndGrades(course_id, examID);
                setStudentsData(data);
            } catch (error) {
                console.error("Error fetching feedback and grades:", error);
            }
        }

        loadExams();
    }, [course_id]);

    useEffect(() => {
        if (selectedExam) { // Ensure there's a selected exam
            async function fetchData() {
                try {
                    const data = await fetchFeedbackAndGrades(course_id, selectedExam);
                    setStudentsData(data);
                } catch (error) {
                    console.error("Error fetching feedback and grades:", error);
                }
            }

            fetchData();
        }
    }, [course_id, selectedExam]); // React to changes in selectedExam


    const handleExamChange = (e) => {
        const selectedExamID = e.target.value;
        setSelectedExam(selectedExamID);
        const exam = exams.find(exam => exam.Exam_ID.toString() === selectedExamID);
        setMaxPoints(exam ? exam.max_points : null);
    };


    if (role === 'instructor') {
        return (
            <>
                <>
                    {banner.message && (
                        <div className={`banner banner-${banner.type}`}>
                            {banner.message}
                        </div>
                    )}
                    {/* Rest of your component */}
                </>

                <HeaderLoggedIn role={role} />
                <div className="gradesContainer">
                    <div className="grades-main-content">
                        <div className="grades-nav">
                            <Link to={`/instructor/coursehome/${course_id}`} >Home</Link>
                            <Link to={`/instructor/announcments/${course_id}`} >Announcements</Link>
                            <Link to={`/instructor/syllabus/${course_id}`}>Syllabus</Link>
                            <Link to={`/instructor/modules/${course_id}`}>Modules</Link>
                            <Link to={`/instructor/exams/${course_id}`} >Exams</Link>
                            <Link to={`/instructor/grades/${course_id}`} className="exams-selected">Grades</Link>
                            <Link to={`/instructor/people/${course_id}`}>People</Link>
                            <Link to={`/instructor/reports/${course_id}`}>Reports</Link>
                        </div>
                        <div className="grades-content">
                            <h2 className="title">Student Feedback & Grades</h2>
                            <label htmlFor="examSelect">Select Exam:&nbsp;&nbsp;</label>
                            {/* Dropdown for exams */}
                            <select id="examSelect" onChange={handleExamChange} value={selectedExam}>
                                {exams.length > 0 && exams.map(exam => (
                                    <option key={exam.Exam_ID} value={exam.Exam_ID}>
                                        {exam.Exam_Name}
                                    </option>
                                ))}
                            </select>
                            <table id="gradesTable">
                                <thead>
                                    <tr>
                                        <th scope="col">Student</th>
                                        <th scope="col">Score</th>
                                        <th scope="col">Feedback</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentsData.map((student, index) => (
                                        <tr key={index}>
                                            <td scope="row">{student.StudentName}</td>
                                            <td onClick={() => {
                                                // If the cell is already in edit mode, don't do anything
                                                if (editingGrade !== index) {
                                                    setEditingGrade(index);
                                                    setEditedGrade(student.Grade || "");
                                                }
                                            }}
                                            >
                                                {editingGrade === index ? (
                                                    <input
                                                        type="number"
                                                        value={editedGrade}
                                                        onChange={e => {
                                                            const value = e.target.value;

                                                            // If the input is not empty and the value is greater than maxPoints, show an alert
                                                            if (value !== '' && Number(value) > maxPoints) {
                                                                alert(`The grade cannot be greater than the maximum points of ${maxPoints}.`);
                                                                // Optionally reset the value to maxPoints or keep it empty/invalid so the user must fix it
                                                                setEditedGrade(maxPoints.toString()); // If you want to reset to the max value
                                                                // setEditedGrade(''); // If you want to force the user to enter a valid value
                                                            } else {
                                                                setEditedGrade(value); // If within range or empty, allow the change
                                                            }
                                                        }}
                                                        max={maxPoints}
                                                        onBlur={async () => {
                                                            // Check if there was an actual change before calling the API
                                                            if (editedGrade !== student.Grade) {
                                                                try {
                                                                    if(!editedGrade)
                                                                        {
                                                                            editedGrade = 0;
                                                                        }
                                                                    if(!student.Instructor_Feedback)
                                                                        {
                                                                            student.Instructor_Feedback = "";
                                                                        }
                                                                    const response = await saveGradesAndFeedback(student.Student_ID, selectedExam, editedGrade, student.Instructor_Feedback);
                                                                    if (response.message) {
                                                                        console.log(response.message);
                                                                        // Update the student's grade in the local state to reflect the change
                                                                        setStudentsData(currentStudents => currentStudents.map(s => {
                                                                            if (s.Student_ID === student.Student_ID) {
                                                                                return { ...s, Grade: editedGrade };
                                                                            }
                                                                            return s;
                                                                        }));
                                                                        showBanner(response.message, 'success');
                                                                    } else {
                                                                        showBanner(response.error || 'Failed to save feedback', 'failure');
                                                                    }
                                                                } catch (error) {
                                                                    console.error("Error saving edited grade for student:", student.Student_ID, error);
                                                                    showBanner(error.message || 'An error occurred while saving', 'failure');
                                                                }
                                                            }
                                                            // Make the cell non-editable
                                                            setEditingGrade(null);
                                                        }}
                                                    />
                                                ) : student.Grade}
                                            </td>
                                            <td><button className="feedback-btn" onClick={() => handleOpenFeedbackModal(student)}>View Feedback</button></td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {showModal && (
                    <div className="feedback-modal">
                        <div className="feedback-modal-content">
                            <div className="modal-header">
                                <h3>Provide Feedback</h3>
                                <span className="close-feedback-modal" onClick={closeFeedbackModal}>&times;</span>
                            </div>
                            <textarea
                                value={feedback}
                                onChange={handleFeedbackChange}
                                placeholder="Enter feedback for the student here."
                            />
                            <button onClick={handleSaveChanges}>Save Changes</button>
                        </div>
                    </div>
                )}
                <Footer />
            </>
        );
    }
    else {
        return <Navigate to="/" replace={true} />
    };
};

export default Grades;
