import React from 'react';
import '../css/manage-students.css';
import { updateUser, recordActivity } from '../services/api';

const UpdateUserForm = ({ role, initialData, goBack }) => {
    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = {
            id: initialData['User_ID'], // Assuming you have an ID field in initialData
            firstName: e.target.firstName.value,
            lastName: e.target.lastName.value,
            email: e.target.email.value,
            password: e.target.password.value,
            dob: e.target.dob.value,
            mobile: e.target.mobile.value,

        };

        console.log("Updating User:", user);

        try {
            const response = await updateUser(user);
            alert(response.message);
            const activityData = {
                username: user.firstName + " " + user.lastName,
                activity_type: `updated their profile at ${new Date().toLocaleString()}`,
              };
              await recordActivity(activityData);
            goBack();
        } catch (error) {
            console.error("Error updating user:", error);
            alert("Error updating user.");
        }
    };

    return (
        <div className="students-page">
            <button onClick={goBack} className="back">Back to Manage {role}</button>
            <h2>Update {role}</h2>
            <form id="updateRoleForm" onSubmit={handleSubmit}>
                <label htmlFor="firstName">First Name:</label>
                <input type="text" id="firstName" defaultValue={initialData['First_Name']} required />

                <label htmlFor="lastName">Last Name:</label>
                <input type="text" id="lastName" defaultValue={initialData['Last_Name']} required />

                <label htmlFor="email">Email:</label>
                <input type="text" id="email" defaultValue={initialData['Email_ID']} required disabled />
                <label htmlFor="dob">DOB</label>
                <input type="date"  required id="dob" name="dob" defaultValue={initialData['DOB']}/>
                {/* Add new field for mobile number */}
                <label htmlFor="mobile">Mobile Number</label>
                <input type="text"  required id="mobile" name="mobile"
                    pattern="[0-9]{10}" title="Please enter a valid 10 digit mobile number" defaultValue={initialData['mobile_number']}
                />


                <label htmlFor="password">Password:</label>
                <input type="password" id="password" defaultValue={initialData['Password']} required />

                <label htmlFor="confirmPassword">Confirm Password:</label>
                <input type="password" id="confirmPassword" defaultValue={initialData['Password']} required />
            
                 <button type="submit" style={{backgroundColor: "#ffa500"}}>
                    Update {role}
                </button>
                </form>
        </div>
    );
};

export default UpdateUserForm;
