import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import { fetchForumMessages, sendForumMessage, fetchSenderName, fetchForumList } from '../../services/api';
import './discussionForum.css';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import jwt_decode from 'jwt-decode';

const DiscussionForum = () => {
    document.title = "Discussion Forum";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const senderId = decodedToken.user_id;// Replace with login user details
    const role = decodedToken.role.toLowerCase();
    const [receiverName, setReceiverName] = useState('');
    const [users, setUsers] = useState([]); // To store the list of users
    const [selectedUser, setSelectedUser] = useState(null);
    const [courseId, setCourseId] = useState(null);
    const discussion_participation_status = decodedToken.permissions.discussion_participation === '0' ? false : true;

    const fetchAndRefreshMessages = (selectedUser) => {

        if (selectedUser) {
            const newcourseId = selectedUser.course_id;
            fetchForumMessages(senderId, newcourseId)
                .then((data) => {
                    setMessages(data);
                })
                .catch((error) => {
                    console.error('Error fetching messages:', error);
                });
        }
    };
    const fetchForums = () => {
        fetchForumList(senderId, role)
            .then(data => {
                if (data.length > 0) {
                    setUsers(data);
                }
                else {
                    setUsers([]);
                }

            })
            .catch(error => {
                console.error('Error fetching user list:', error);
            });
    };
    const handleForumSelect = (selectedUser) => {
        setSelectedUser(selectedUser);
        setCourseId(selectedUser.course_id); // Assuming User_ID is the unique identifier
        setReceiverName(selectedUser.course_name);
        fetchAndRefreshMessages(selectedUser);
    };
    useEffect(() => {
        fetchForums();
        // Fetch messages when the component mounts
        fetchAndRefreshMessages(selectedUser);
    }, []);

    const handleSendMessage = () => {
        if(!discussion_participation_status){
            alert("You don't have permission to send messages.");
        }
        else{
        sendForumMessage({ messageContent: newMessage, senderId, courseId })
            .then(() => {
                setNewMessage('');
                fetchAndRefreshMessages(selectedUser);
            })
            .catch(error => {
                console.error('Error sending message:', error);
            });
        setNewMessage('');
    }
    };

    if (role === 'student' || role === 'instructor') {

        return (
            <>
                <HeaderLoggedIn role={role} />
                <div className="content-containerchat">
                    <div className="left-panelchat">
                        <div className="section">
                            <div className="section-title">All Discussion Forums</div>
                            <div className="section-content">
                                <ul className="forum-list" id="forumList">
                                    {users.map((user) => (
                                        <li
                                            key={user.course_name}
                                            className="forum-list-item"
                                            onClick={() => handleForumSelect(user)}
                                        >
                                            <div>
                                                <div className="forum-name">
                                                    Discussion-Forum: {user.course_name}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="right-containerchat" >

                        <h2>{receiverName}</h2>

                        {receiverName !== '' && (
                          


                            <div className="right-panelchat" >
                                {messages.map((msg, index) => (
                                    <div
                                        key={index}
                                        className="post-container"
                                    >
                                        <div key={index} className={`message ${msg.Sender_ID === senderId ? 'my-message' : 'other-message'}`}>
                                            <div className="author">{msg.Sender_ID === senderId ? 'you' : (msg.role.toLowerCase() === 'instructor' ? 'Instructor': String(msg.Last_Name + ', ' + msg.First_Name))}</div>
                                            <div className="content">{msg.Message_Content}</div>
                                        </div>
                                    </div>
                                ))}
                                <div className="input-container">
                                    <textarea
                                        placeholder="Type your post here"
                                        value={newMessage}
                                        onChange={e => setNewMessage(e.target.value)}
                                    />
                                    <button onClick={handleSendMessage}>Post</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    else {
        return <Navigate to="/" replace={true} />
    };
}

export default DiscussionForum;

