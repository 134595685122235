import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto'; // Importing auto is necessary for Chart.js 3

const GradesBarChart = ({ gradesData, maxScore }) => {
    // Calculate the interval size for 10 score ranges
    const intervalSize = maxScore / 10;

    // Initialize an array to hold the count of students in each score range
    let scoreCounts = new Array(10).fill(0);

    // Increment the count for the corresponding score range
    gradesData.forEach(({ Grade }) => {
        const grade = parseInt(Grade, 10);
        const index = Math.min(Math.floor(grade / intervalSize), 9); // Ensure the index is within bounds
        scoreCounts[index]++;
    });

    // Create labels for the x-axis based on the score ranges
    let labels = [];
    for (let i = 0; i < 10; i++) {
        labels.push(`${Math.round(i * intervalSize)} - ${Math.round((i + 1) * intervalSize)}`);
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Number of Students',
                data: scoreCounts,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Students',
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Score Ranges',
                },
            },
        },
        plugins: {
            legend: {
                display: true, // Set to true if you want to display the dataset label
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default GradesBarChart;
