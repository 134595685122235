import React, { useState, useEffect } from 'react';
import './processesAndPolicies.css';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import jwt_decode from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import { fetchQAPolicies, createQAPolicy, updateQAPolicy, deleteQAPolicy } from '../../services/api';

const ProcessAndPolicies = () => {
  document.title = "Processes and Policies";
  const decodedToken = jwt_decode(localStorage.getItem('token'));
  const role = decodedToken.role.toLowerCase();
  const [qapolicies, setQAPolicies] = useState([]);
  const [newPolicy, setNewPolicy] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newResponsibleParty, setNewResponsibleParty] = useState('Instructors');
  const view_feedback_status = decodedToken.permissions.view_feedback === '0'? false : true;
  const create_policies_status = decodedToken.permissions.create_policies === '0'? false : true;

  function isAlphanumeric(inputString) {
    // Use a regular expression to match alphanumeric characters
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    return alphanumericRegex.test(inputString);
  }
  function fetchpolicy() {
    fetchQAPolicies()
      .then((data) => setQAPolicies(data))
      .catch((error) => console.error('Error fetching QAPolicies: ', error));
  }

  useEffect(() => {
    fetchpolicy();
  }, []);

  const [isUpdatePopupOpen, setUpdatePopupOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState({ ProcessPolicyName: '', Description: '', ResponsibleParty: '' });

  const openUpdatePopup = (policy) => {
    setSelectedPolicy(policy);
    setUpdatePopupOpen(true);
  };

  const handleUpdate = (id) => {
    const policyToUpdate = qapolicies.find((policy) => policy.id === id);
    if (policyToUpdate) {
      openUpdatePopup(policyToUpdate);
    }
  };

  const closeUpdatePopup = () => {
    setSelectedPolicy({ ProcessPolicyName: '', Description: '', ResponsibleParty: '' });
    setUpdatePopupOpen(false);
  };

  const handleUpdateSubmit = () => {
    if (selectedPolicy.ProcessPolicyName === '') {
      alert('Policy name cannot be empty');

    }
    else if (isAlphanumeric(selectedPolicy.ProcessPolicyName) === false) {
      alert('Policy name cannot contain special characters');
    }
    else if (selectedPolicy.Description === '') {
      alert('Description cannot be empty');

    }
    else if (isAlphanumeric(selectedPolicy.Description) === false) {
      alert('Description cannot contain special characters');
    }
    else
      updateQAPolicy(selectedPolicy.id, {
        ProcessPolicyName: selectedPolicy.ProcessPolicyName,
        Description: selectedPolicy.Description,
        ResponsibleParty: selectedPolicy.ResponsibleParty,
      })
        .then(() => {
          closeUpdatePopup();
          fetchpolicy();
        })
        .catch((error) => console.error('Error updating policy: ', error));
  };
  const handleDelete = (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this policy?');
    if (confirmDelete) {
      deleteQAPolicy(id)
        .then(() => {
          fetchpolicy();
        })
        .catch((error) => console.error('Error deleting policy: ', error));
    }
  };

  const handleAdd = () => {
    if (create_policies_status === false) {
      alert('You do not have permission to create policies');
    }
    else if (newPolicy === '') {
      alert('Policy name cannot be empty');

    }
    else if (isAlphanumeric(newPolicy) === false) {
      alert('Policy name cannot contain special characters');
    }
    else if (newDescription === '') {
      alert('Description cannot be empty');

    }
    else if (isAlphanumeric(newDescription) === false) {
      alert('Description cannot contain special characters');
    }
    else {
      createQAPolicy({ newPolicy, newDescription, newResponsibleParty })
        .then((response) => {
          fetchpolicy();
        })
        .catch((error) => console.error('Error creating policy: ', error));
    }
    setNewPolicy('');
    setNewDescription('');
  };
  if (role === 'qa') {
    return (
      <>
        <HeaderLoggedIn role={role} />
        <div className="content-containerchat">
          <div className="left-panelchat">
            <a className="menuButton" href="/QADashboard">QA Dashboard</a>
            <a className="menuButton" href="/program-objectives">Program Objectives</a>
            {view_feedback_status &&
              <a className="menuButton" href="/feedback">Program Feedback</a>
            }
            <a className="menuButton" href="/student_reports">Student Performance Analysis</a>
          </div>
          <div className="right-containerchat">
            <div className="section">
              <h2>Policies</h2>
              <table id="policy-table">
                <thead>
                  <tr>
                    <th>Process/Policy Name</th>
                    <th>Description</th>
                    <th>Responsible Party</th>
                    <th>Operations</th>
                  </tr>
                </thead>
                <tbody>
                  {qapolicies.map((policy) => (
                    <tr key={policy.id}>
                      <td>{policy.ProcessPolicyName}</td>
                      <td>{policy.Description}</td>
                      <td>{policy.ResponsibleParty}</td>
                      <td>
                        <button className="update-button" onClick={() => handleUpdate(policy.id)}>Update</button>
                        <button className="delete-button" onClick={() => handleDelete(policy.id)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="section">
              <div className="form-container">
                <h3>Add new Quality assurance processes and policies</h3>
                <input type="text" placeholder="Process/Policy Name" value={newPolicy} onChange={e => setNewPolicy(e.target.value)} />
                <textarea placeholder="Description" value={newDescription} onChange={e => setNewDescription(e.target.value)}></textarea>
                <h5>Select Responsible Party</h5>
                <select
                  value={newResponsibleParty}
                  onChange={(e) => setNewResponsibleParty(e.target.value)}
                >
                  <option value="Instructors" >Instructor</option>
                  <option value="Quality Assurance Officer">Quality Assurance Officer</option>
                  <option value="Program Coordinator">Program Coordinator</option>
                </select>
                <button className="add-button" onClick={() => handleAdd()} >Add</button>
              </div>
            </div>
          </div>
        </div>
        {isUpdatePopupOpen && (
          <div className={`update-policy-popup ${isUpdatePopupOpen ? 'visible' : ''}`}>
            <div className="update-popup-content">
              <h3>Update Policy</h3>
              <input
                type="text"
                placeholder="Process/Policy Name"
                name="ProcessPolicyName"
                value={selectedPolicy.ProcessPolicyName || ''}
                onChange={(e) => setSelectedPolicy({ ...selectedPolicy, ProcessPolicyName: e.target.value })}
              />
              <textarea
                placeholder="Description"
                name="Description"
                value={selectedPolicy.Description || ''}
                onChange={(e) => setSelectedPolicy({ ...selectedPolicy, Description: e.target.value })}
              />
              <select
                value={selectedPolicy.ResponsibleParty || 'Instructors'}
                onChange={(e) => setSelectedPolicy({ ...selectedPolicy, ResponsibleParty: e.target.value })}
              >
                <option value="Instructors">Instructors</option>
                <option value="Quality Assurance Officer">Quality Assurance Officer</option>
                <option value="Program Coordinator">Program Coordinator</option>
              </select>
              <button className="update-button" onClick={handleUpdateSubmit}>Update</button>
              <button className="cancel-button" onClick={closeUpdatePopup}>Cancel</button>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
  else {
    return <Navigate to="/" replace={true} />
  };
};

export default ProcessAndPolicies;
