import React from 'react';
import '../css/about.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { FaUser } from 'react-icons/fa';
import { validateToken } from '../utils/authUtils';
import HeaderLoggedIn from '../components/headerLoggedIn';
import jwt_decode from 'jwt-decode';

const About = () => {
    document.title = "About Us";
    const token = localStorage.getItem('token');
    var role = '';
    if (token) {
        role = jwt_decode(token).role.toLowerCase();
    }
    return (
        <>
           {validateToken() ? <HeaderLoggedIn role={role}/> : <Header />}
            
            <div className="container-about">
                <div className="section-about">
                    <h2 className='abouth2'>Overview</h2>
                    <p>Welcome to the Performance of MSC Academic Program website! Our aim is to provide an effective platform
                        for measuring and assessing the performance of the Computer Science program. We believe that performance
                        measurement is crucial for evaluating the effectiveness of the program and ensuring the continuous
                        improvement of our academic offerings.</p>
                </div>

                <div className="section-about">
                    <h2 className='abouth2'>Our Project Goals</h2>
                    <p>
                        We have set clear objectives for our project:
                    </p>
                    <ul className='aboutheading'>
                        <li>Enhance the educational experience for students in the Computer Science program.</li>
                        <li>Streamline program management for instructors and administrators.</li>
                        <li>Improve assessment and reporting mechanisms for program evaluation.</li>
                        <li>Promote communication and collaboration among all stakeholders.</li>
                    </ul>
                </div>
                <div className="section-about">
                    <h2 className='abouth2'>Our Vision</h2>
                    <p>
                        Our vision is to create a user-friendly platform that empowers students and educators alike, fostering a
                        supportive learning environment and ensuring the program's continued success.
                    </p>
                </div>
                <div className="section-about">
                    <h2 className='abouth2'>Our Team</h2>
                    <p>
                        Our team consists of dedicated individuals who are passionate about education and technology. We are
                        committed to delivering a high-quality solution that meets the needs of our users and contributes to the
                        success of the academic program.
                    </p>
                    <div className="team">
                        <div className="team-member">
                        <FaUser />
                            <h3>Akanksha Tomar</h3>
                        </div>
                        <div className="team-member">
                        <FaUser />
                            <h3>Shraddha Varekar</h3>
                        </div>
                        <div className="team-member">
                        <FaUser />
                            <h3>Sri Harrsha Kolli</h3>
                        </div>
                        <div className="team-member">
                        <FaUser />
                            <h3>Raj Kumar Mannam</h3>
                        </div>
                        <div className="team-member">
                        <FaUser />
                            <h3>Gokul Ravi</h3>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
}

export default About;
