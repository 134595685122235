import React, { useState, useEffect } from 'react';
import { getUpcomingExams } from '../services/api';
import '../css/student_dashboard.css';

const UpcomingExams = () => {
    const [exams, setExams] = useState([]);

    const isUpcoming = (dateString) => {
        const examDate = new Date(dateString);
        const currentDate = new Date();
        return examDate >= currentDate;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const examData = await getUpcomingExams();
                setExams(examData);
            } catch (error) {
                console.error("Error fetching exams:", error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="projectsContainer" id="style-2">
            <span className="projectContainerHeader">
                <h3 className="projectTitle title">Exams and Assessments</h3>
            </span>
            {exams.map(exam => (
                <div key={exam.Course_Name} className="project box">
                    <span className="projectHeader">
                        <h4>{exam.Course_Name}</h4>
                    </span>
                    <h5>{exam.Exam_Date}</h5>
                    {isUpcoming(exam.Exam_Date) ? <span>Upcoming</span> : <span style={{color:'red'}}>Past</span>}
                </div>
            ))}
        </div>
    );
};

export default UpcomingExams;
