// import React from 'react';
// import '../css/style.css';
// import logo from '../images/logo4.png';
// import QADashboard from '../pages/QAOfficer/QADashboard';

// function HeaderLoggedIn() {
//     return (
//         <header className="headerContainer">
//             <div className="headerLogo">
//                 <a href="/" target="_top">
//                     <img src={logo} alt="Logo" className="logoImage" />
//                 </a>
//             </div>
//             <ul className="menu">
//                 <li><a href="/" target="_top">Home</a></li>
//                 <li><a href="/courses" target="_top">Courses</a></li>
//                 <li><a href="/discussionForum" target="_top">Discussion-Forums</a></li>
//                 <li><a href="/chat" target="_top">Chat</a></li>
//                 <li><a href="#">User-Dashboard</a>
//                     <ul className="dropdown-contentHeader">
//                         <li><a href="../pages/instructor/dashboard.html" target="_top">Instructor Dashboard</a></li>
//                         <li><a href="../pages/studentDashboard/studentDashboard.html" target="_top">Student Dashboard</a></li>
//                         <li><a href="../pages/adminDashboard/admin-dashboard.html" target="_top">Admin Dashboard</a></li>
//                         <li><a href = "/QADashboard" target="_top">QA-officer Dashboard</a></li>
//                         <li><a href="../pages/coordinator_dashboard.html" target="_top">Program-coordinator Dashboard</a></li>
//                     </ul>
//                 </li>
//                 <li><a href="/auth/logout" target="_top">Log Out</a></li>
//             </ul>
//         </header>
//     );
// }

// export default HeaderLoggedIn;

import React, { useState } from 'react';
import '../css/style.css';
import logo from '../images/logo4.png';
import QADashboard from '../pages/QAOfficer/QADashboard';

function HeaderLoggedIn({ role }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <header className="headerContainer">
            <div className="headerLogo">
                <a href="/" target="_top">
                    <img src={logo} alt="Logo" className="logoImage" />
                </a>
            </div>
            <button className="hamburger" onClick={toggleMenu}>
                ☰
            </button>
            <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
                <li><a href="/" target="_top">Home</a></li>
                <li><a href="/courses" target="_top">Courses</a></li>
                {(role === 'student' ||role === 'instructor') && (
                    <li><a href="/discussionForum" target="_top">Discussion-Forums</a></li>
                )}
                <li><a href="/chat" target="_top">Chat</a></li>
                {role === 'qa' && (
                    <li>
                        <a href="/QADashboard" target="_top">QA-officer Dashboard</a>
                    </li>
                )}
                {role === 'instructor' && (
                    <li><a href="/instructor/dashboard" target="_top">Instructor Dashboard</a></li>
                )}
                {role === 'student' && (
                    <li><a href="/student_dashboard" target="_top">Student Dashboard</a></li>
                )}
                {role === 'coordinator' && (
                    <li><a href="/coordinator_dashboard" target="_top">Program-coordinator Dashboard</a></li>
                )}
                {role === 'admin' && (
                    <li><a href="/adminDashboard" target="_top">Admin Dashboard</a></li>
                )}
                <li><a href="/" target="_top" onClick={() => localStorage.removeItem('token')}>Log Out</a></li>
            </ul>
        </header>
    );
}

export default HeaderLoggedIn;