import React, { useState, useEffect } from 'react';
import '../css/about.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { validateToken } from '../utils/authUtils';
import HeaderLoggedIn from '../components/headerLoggedIn';
import jwt_decode from 'jwt-decode';
import { fetchPOSObjective } from '../services/api';

const ProgramObjectives = () => {
    document.title = "Program Objectives";
    const token = localStorage.getItem('token');
    const [POSdata, setPOSData] = useState([]);
    var role = '';
    if (token) {
        role = jwt_decode(token).role.toLowerCase();
    }
    useEffect(() => {
        fetchAllObjectives();
    }, []);

    const fetchAllObjectives = () => {
        fetchPOSObjective()
            .then((data) => {
                setPOSData(data);
            })
            .catch((error) => console.error('Error fetching recommendations: ', error));
    }
    const groupedData = POSdata.reduce((result, item) => {
        if (!result[item.Course_Name]) {
            result[item.Course_Name] = [];
        }
        result[item.Course_Name].push(item.Objective_Name);
        return result;
    }, {});
    return (
        <>
            {validateToken() ? <HeaderLoggedIn role={role} /> : <Header />}

            <div className="content-containerPOS">
                <h2>Mapping of Course to Program Objectives</h2>
                <div className="table-containerPOS">
                    <table >
                        <thead>
                            <tr>
                                <th>Course</th>
                                <th>Program Objectives</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(groupedData).map((course, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td rowSpan={groupedData[course].length}>{course}</td>
                                        <td>{groupedData[course][0]}</td>
                                    </tr>
                                    {groupedData[course].slice(1).map((objective, subIndex) => (
                                        <tr key={subIndex}>
                                            <td>{objective}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ProgramObjectives;
