import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import Quill from 'quill';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import { getAnnouncements, createAnnouncement } from '../../services/api';
import './announcements.css';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const Announcements = () => {
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState('');
    const [details, setDetails] = useState('');
    const [quillInstance, setQuillInstance] = useState(null);
    const { course_id } = useParams();
    const [announcements, setAnnouncements] = useState([]);
    const [banner, setBanner] = useState({ message: '', type: '' });

    const showBanner = (message, type) => {
        setBanner({ message, type });
        // Automatically hide the banner after 3 seconds
        setTimeout(() => setBanner({ message: '', type: '' }), 3000);
    };

    const handleCreateAnnouncement = async () => {
        try {
            const detailsHtml = quillInstance.root.innerHTML; // This gets the HTML content from Quill
            const response = await createAnnouncement(course_id, title, detailsHtml);

            console.log(response); // Log the response to see what it returns

            if (response.message === 'Announcement was added.') {
                setTitle(''); // Reset title
                quillInstance.setText(''); // Reset Quill editor content
                setShowModal(false); // Close the modal

                // Fetch the updated list of announcements and reverse it to display the latest on top
                const updatedAnnouncements = await getAnnouncements(course_id);
                setAnnouncements(updatedAnnouncements.reverse());
                showBanner(response.message, 'success');
            } else {
                // Handle any errors, such as displaying a notification to the user
                showBanner(response.error || 'Failed to save feedback', 'failure');
                console.error('Error creating announcement:', response.error);
            }
        } catch (error) {
            showBanner(error.message || 'An error occurred while saving', 'failure');
            console.error('Failed to create announcement:', error);
        }
    };



    useEffect(() => {
        if (!quillInstance && showModal) {
            setTimeout(() => {
                const quill = new Quill('#quill-editor', {
                    theme: 'snow'
                });
                setQuillInstance(quill);
            }, 50);
        }
    }, [quillInstance, showModal]);

    useEffect(() => {
        getAnnouncements(course_id)
            .then(data => {
                setAnnouncements(data.reverse()); // Update state with the fetched announcements
            })
            .catch(error => {
                console.error('Failed to fetch announcements:', error);
            });
    }, [course_id]);

    const handleModalToggle = () => {
        setShowModal(!showModal);
    }

    document.title = "Announcements";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();

    if (role === 'instructor') {
        return (
            <>
                <>
                    {banner.message && (
                        <div className={`banner banner-${banner.type}`}>
                            {banner.message}
                        </div>
                    )}
                    {/* Rest of your component */}
                </>
                <Helmet>
                    <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
                </Helmet>
                <HeaderLoggedIn role={role} />
                <div className="announcementsContainer">
                    <div className="announcements-main-content">
                        <div className="announcements-nav">
                            <Link to={`/instructor/coursehome/${course_id}`}>Home</Link>
                            <Link to={`/instructor/announcments/${course_id}`} className="announcements-selected">Announcements</Link>
                            <Link to={`/instructor/syllabus/${course_id}`}>Syllabus</Link>
                            <Link to={`/instructor/modules/${course_id}`}>Modules</Link>
                            <Link to={`/instructor/exams/${course_id}`}>Exams</Link>
                            <Link to={`/instructor/grades/${course_id}`}>Grades</Link>
                            <Link to={`/instructor/people/${course_id}`}>People</Link>
                            <Link to={`/instructor/reports/${course_id}`}>Reports</Link>
                        </div>
                        <div className="announcements-content">
                            <button className="create-announcement-btn" onClick={handleModalToggle}>Create Announcement</button>

                            {showModal && (
                                <div className="announcement-modal" style={{ display: showModal ? 'block' : 'none' }}>
                                    <div className="announcement-modal-content">
                                        <span className="announcement-close" onClick={() => setShowModal(false)}>&times;</span>
                                        <h2 className="announcement-modal-title">Create New Announcement</h2>
                                        <label className="announcement-label" htmlFor="announcementTitle">Title:</label>
                                        <input
                                            className="announcement-input"
                                            type="text"
                                            id="announcementTitle"
                                            value={title}
                                            onChange={e => setTitle(e.target.value)}
                                            required
                                        />
                                        <label className="announcement-label" htmlFor="announcementDetails">Description:</label>
                                        <div id="quill-editor"></div>
                                        <button
                                            className="announcement-modal-btn"
                                            onClick={handleCreateAnnouncement}
                                            disabled={!title} // Removed the check for details as it's handled by Quill
                                        >
                                            Create
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div className="announcements-content">
                                {announcements.map((announcement, index) => (
                                    <div key={index} className="announcements-section">
                                        <h2>{announcement.Announcment_Title}</h2>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: announcement.Announcment_Description }}
                                        ></div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    else {
        return <Navigate to="/" replace={true} />
    };
};

export default Announcements;
