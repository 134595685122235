import React, { useState, useEffect } from 'react';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import './instructorProfile.css';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { getInstructorInfo, updateInstructorInfo } from '../../services/api'; // Ensure this import path is correct

const InstructorProfile = () => {
    document.title = "Instructor Profile";
    const [profile, setProfile] = useState({
        fullName: "",
        email: "",
        about: ""
    });

    // State for form validation error messages
    const [errors, setErrors] = useState({});

    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const user_id = decodedToken.user_id; // Assuming the token has user_id
    const edit_profile_status = decodedToken.permissions.edit_profile === '0'? false : true;

    useEffect(() => {
        if (edit_profile_status) {
            fetchInstructorProfile();
        }
    }, [edit_profile_status, user_id]);

    const fetchInstructorProfile = async () => {
        try {
            const data = await getInstructorInfo(user_id);
            if (data) {
                setProfile({
                    fullName: data.Full_Name,
                    email: data.Email,
                    about: data.About
                });
            } else {
                // Handle the case where there is no data or an error occurred
                console.error('Error fetching instructor profile:', data.message);
            }
        } catch (error) {
            console.error('Error fetching instructor profile: ', error);
        }
    };

    const validateForm = () => {
        let tempErrors = {};
        if (!profile.fullName) tempErrors.fullName = "Full Name is required.";
        if (!profile.email) tempErrors.email = "Email is required.";
        if (!/\S+@\S+\.\S+/.test(profile.email)) tempErrors.email = "Email is invalid.";
        if (!profile.about) tempErrors.about = "About section is required.";
        setErrors(tempErrors);

        return Object.keys(tempErrors).length === 0;
    };

    const saveChanges = async () => {
        if (!edit_profile_status) {
            alert('You do not have permission to edit profile');
            return;
        }

        if (validateForm()) {
            try {
                const response = await updateInstructorInfo(user_id, profile.fullName, profile.email, profile.about);
                if (response && response.success) {
                    alert('Profile updated successfully');
                } else {
                    // Handle the case where the update was not successful
                    alert('Error updating profile: ' + response.message);
                }
            } catch (error) {
                console.error('Error updating profile: ', error);
                alert('An error occurred while updating the profile.');
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({ ...prevProfile, [name]: value }));
        // Clear the errors as the user types
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    if (role === 'instructor') {
        return (
            <>
                <HeaderLoggedIn role={role} />
                <div className="instructorProfileContainer">
                    <main className="profile-main-content" aria-label="Main Content">
                        <h1 className="profile-heading">Instructor Profile</h1>
                        <div className="profile-form">
                            <label htmlFor="fullName">Full Name:</label>
                            <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                placeholder="Enter full name"
                                value={profile.fullName}
                                onChange={handleChange}
                            />
                            {errors.fullName && <div className="error">{errors.fullName}</div>}

                            <label htmlFor="email">Email:</label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Enter email"
                                value={profile.email}
                                onChange={handleChange}
                            />
                            {errors.email && <div className="error">{errors.email}</div>}

                            <label htmlFor="about">About:</label>
                            <textarea
                                id="about"
                                name="about"
                                rows="5"
                                placeholder="Tell us something about yourself"
                                value={profile.about}
                                onChange={handleChange}
                            ></textarea>
                            {errors.about && <div className="error">{errors.about}</div>}

                            <button type="button" onClick={saveChanges}>Save Changes</button>
                        </div>
                    </main>
                </div>
                <Footer />
            </>
        );
    } else {
        return <Navigate to="/" replace={true} />;
    }
};

export default InstructorProfile;
