import React, {useState, useEffect} from 'react';
import Footer from '../../components/footer';
import '../../css/admin-dashboard.css';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import PermissionsForm from '../../components/PermissionsForm';
import Manage from '../../components/Manage';
import AddNewForm from '../../components/AddNewForm';
import jwt_decode from 'jwt-decode';
import { fetchUsersByRole, deleteUser,fetchPermissionsByRole } from '../../services/api';
import UpdateUserForm from '../../components/UpdateUserForm';
import { Navigate } from 'react-router-dom';


import { fetchRecentActivities } from '../../services/api';
import AdminAnalytics from '../../components/adminAnalytics';

const AdminDashboard = () => {
    document.title = "Admin Dashboard";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();

    const [selectedRole, setSelectedRole] = useState(null);
    const [showPermissions, setShowPermissions] = useState(false);
    const [showManage, setShowManage] = useState(false);
    const [manageRole, setManageRole] = useState(null);
    const [showAddNewForm, setShowAddNewForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [userToUpdate, setUserToUpdate] = useState(null);
    const [activities, setActivities] = useState([]);

    const [showAdminAnalytics, setShowAdminAnalytics] = useState(false);
    const [usersPermissionData, setUsersPermissionData] = useState([]);


    useEffect(() => {
        // Function to fetch and update activities
        const fetchActivities = async () => {
            try {
                const newActivities = await fetchRecentActivities();
                setActivities(newActivities);
            } catch (error) {
                console.error("Failed to fetch activities:", error);
            }
        };

        // Call once immediately
        fetchActivities();
        fetchPermsissions();
        // Then call every 3 seconds
        const interval = setInterval(fetchActivities, 3000);

        // Cleanup on unmount (clear the interval)
        return () => clearInterval(interval);
    }, []);  // Empty dependency array to run the effect only on mount and unmount


    const dummyData = [
        {name: 'Shraddha Varekar'},
        {name: 'Akanksha Tomar'},
        {name: 'Sri Harrsha Kolli'},
        {name: 'Raj Kumar Mannam'},
        {name: 'Gokul Ravi'},
    ];

    const [usersData, setUsersData] = useState([]);
// Renaming local function to fetchUsers
const fetchUsers = async (role) => {
    try {
        const response = await fetchUsersByRole(role);
        // Checking for existence of data property
        if (response && response.data) {
            console.log("Users:", response.data);
            setUsersData(response.data);
        } else {
            console.error("Received malformed response:", response);
        }
    } catch (error) {
        console.error("Error fetching users:", error);
    }
}

    const fetchPermsissions = async () => {
        try {
            const response = await fetchPermissionsByRole();
            // Checking for existence of data property
            if (response ) {
                setUsersPermissionData(response);
            } else {
                console.error("Received malformed response:", response);
            }
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    }
    
    const handleAddNew = (role) => {
        setSelectedRole(role);
        setShowAddNewForm(true);
    };

    const handleManage = (role) => {
        fetchUsers(role);
        setSelectedRole(role);
        setShowManage(true);
    };

    const handlePermissions = (role) => {
        setSelectedRole(role);
        setShowPermissions(true);
    };

    
const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
        try {
            const response = await deleteUser(id);
            console.log(response.message);
            // Remove the user from the list or refresh the user list
            fetchUsers(selectedRole);
            alert("User deleted successfully!");
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    }
};

const handleUpdate = (user) => {
    setUserToUpdate(user);
    setShowUpdateForm(true);
};


if ( role === 'admin' ) {
    return (
        <>
            <HeaderLoggedIn  role={role}/>
            
            <div className="main-content">
            {showPermissions ? (
    <PermissionsForm 
        role={selectedRole} 
        goBack={() => {
            setShowPermissions(false);
            setSelectedRole(null);
        }}
        data={usersPermissionData ? usersPermissionData : []}
    />
) : showManage ? (
    showAddNewForm ? (
        <AddNewForm
            role={selectedRole}
            goBack={() => {
                fetchUsers(selectedRole); 
                setShowAddNewForm(false);
                setSelectedRole(selectedRole);
            }}
        />
    ) : showUpdateForm ? (
        <UpdateUserForm
            role={selectedRole}
            initialData={userToUpdate}
            goBack={() => {
                fetchUsers(selectedRole);
                setShowUpdateForm(false);
                setUserToUpdate(null);
            }}
        />
    ) : (
        <Manage
            role={selectedRole}
            data={usersData ? usersData : []}
            onAdd={() => handleAddNew(selectedRole)}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            goBack={() => {
                setShowManage(false);
                setSelectedRole(null);
            }}
        />
    )
) : (
            <div className="dashboard">
            {/* Left column */}

            <div className="left-column">
            {showAdminAnalytics ? (
        <>            
            <AdminAnalytics />

        </>
    ) : (
        <>
                <div className="grid-item">
                    <div className="content">Student</div>
                    <button onClick={() => handleManage('student')}>Manage Students</button>
                    <button onClick={() => {
                                    setSelectedRole('student');
                                    setShowPermissions(true);
                                }}>Permission Settings</button>
                </div>
                <div className="grid-item">
                    <div className="content">Instructor</div>
                    <button onClick={() => handleManage('instructor')}>Manage Instructors</button>
                    <button onClick={() => {
                                    setSelectedRole('instructor');
                                    setShowPermissions(true);
                                }}>Permission Settings</button>
                </div>
                <div className="grid-item">
                    <div className="content">QA Officer</div>
                    <button onClick={() => handleManage('qa')}>Manage QA Officers</button>
                    <button onClick={() => {
                                    setSelectedRole('qa');
                                    setShowPermissions(true);
                                }}>Permission Settings</button>
                </div>
                <div className="grid-item">
                    <div className="content">Program Coordinator</div>
                    <button onClick={() => handleManage('coordinator')}>Manage PC</button>
                    <button onClick={() => {
                                    setSelectedRole('coordinator');
                                    setShowPermissions(true);
                                }}>Permission Settings</button>
                </div>
                </>
                 )}
            </div>

            {/* Right column */}
            <div className="right-column">
            {/* Buttons Section */}
<div className="buttons-section">
{showAdminAnalytics && 
            <button className='dashboard-button' onClick={() => setShowAdminAnalytics(false)}
        >Back to Admin Dashboard</button>
        }
    <button className="dashboard-button"
    onClick={() => {
        // direct to course management page
        window.location.href = "/course_manage";
    }
    }>Course Management</button>
    <button className="dashboard-button"
    onClick={() => {
        // direct to program management page
        window.location.href = "/program-objectives";
    }
}
    >Program Objectives</button>
    {/* <button className="dashboard-button">Website Settings</button> */}
    <button className="dashboard-button" onClick={() => setShowAdminAnalytics(true)}>Analytics & Reports</button>

</div>


                {/* Activity Feed */}
                <div className="activity-feed">
                <h2>User Activity</h2>
                <ul>
                    {activities.map((activity, index) => (
                        <li key={index}>
                            <span>{activity.username}</span> {activity.activity_type}
                        </li>
                    ))}
                </ul>
                </div>
            </div>
        </div>
        )}
                {/* Put the footer at absolute bottom */}
                {/* <div className="footer-push"> */}
                {/* if showPermissions selectedRole is PC or QA, add className footer-push, else just keep footer */}
                <div className="footer-push-adm">

                <Footer />
                </div>
            </div>
        </>
    );
}
else {
  return <Navigate to="/" replace={true} />
};
};

export default AdminDashboard;
