import React,{useEffect} from 'react';
import '../css/manage-students.css';

const Manage = ({ role, data, onAdd, onUpdate, onDelete , goBack}) => {


    return (
        <div className="students-pageManage" >
            <h1>Manage {role}s</h1>

            <button id="addBtn" onClick={() => onAdd(role)}>
                Add New {role}
            </button>

            <ul className="student-list" >
                {data.map((item, index) => (
                    <li key={index}>
                        <span className="student-name">{item['First_Name'] + " "+ item["Last_Name"]}</span>
                        <div className="btns">
                            <button onClick={() => onUpdate(item)} className="upd">Update</button>
                            <button onClick={() => onDelete(item['User_ID'])} className='del'>Delete</button>
                        </div>
                    </li>
                ))}
            </ul>
            <button onClick={goBack}>Back</button>
        </div>
    );
};

export default Manage;
