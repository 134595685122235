import React, { useState, useEffect } from 'react';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import jwt_decode from 'jwt-decode';
import { fetchInstructorCourses } from '../../services/api';  // Import the API function
import { useNavigate } from 'react-router-dom'; // Importing the useNavigate hook
import './instructorDashboard.css';
import { Navigate } from 'react-router-dom';

const InstructorDashboard = () => {
    document.title = "Instructor Dashboard";

    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const instructorId = decodedToken.user_id;
    const course_manage_status = decodedToken.permissions.course_manage === '0'? false : true;
    const navigate = useNavigate(); // Declare the navigate function using the useNavigate hook

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const fetchedCourses = await fetchInstructorCourses(instructorId);
                setCourses(fetchedCourses);
            } catch (error) {
                console.error('Error fetching courses: ', error);
            }
        };
        fetchCourses();
    }, []);

    // This function will chunk the courses array into arrays of size 3
    const chunkArray = (array, chunkSize) => {
        const chunked = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunked.push(array.slice(i, i + chunkSize));
        }
        return chunked;
    };

    const chunkedCourses = chunkArray(courses, 3); // Create arrays of 3 courses



    if (role === 'instructor') {
        return (
            <>
                <HeaderLoggedIn role={role} />
                <div className="instructorDashboard">
                    <main className="main-content" aria-label="Main Content">
                        <div className="dashboard-heading">
                            <button className="mbtn" onClick={course_manage_status=== true ? () => navigate('/course_manage'):()=> alert('You do not have permission to access this page')}>Manage Courses</button>
                            <button className="mbtn" id="profileBtn" onClick={() => navigate('/instructor/profile')}>Profile</button>
                        </div>

                        <section className="courses-section">
                            {chunkedCourses.map((courseGroup, index) => (
                                <div className="course-row" key={index}>
                                    {courseGroup.map(course => (
                                        <div className="course-widget" key={course.Course_ID} onClick={() => navigate(`/instructor/coursehome/${course.Course_ID}`)}>
                                            <h3>{course.Course_Name}</h3>
                                            <ul className="course-icon-list">
                                                <li><a href="#" onClick={(e) => { e.stopPropagation(); navigate(`/instructor/announcments/${course.Course_ID}`); }} title="Announcements">🔈</a></li>
                                                <li><a href="#" onClick={(e) => { e.stopPropagation(); navigate(`/instructor/modules/${course.Course_ID}`); }} title="Modules">📑</a></li>
                                                <li><a href="#" onClick={(e) => { e.stopPropagation(); navigate(`/instructor/exams/${course.Course_ID}`); }} title="Assessments">📝</a></li>
                                                <li><a href="#" onClick={(e) => { e.stopPropagation(); navigate(`/instructor/grades/${course.Course_ID}`); }} title="Grades">📊</a></li>
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </section>
                    </main>
                </div>
                <Footer />
            </>
        );
    }
    else {
        return <Navigate to="/" replace={true} />
    };
};

export default InstructorDashboard;
