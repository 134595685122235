import React, { useState, useEffect } from 'react';
import '../css/feedback.css';
import HeaderLoggedIn from '../components/headerLoggedIn';
import Footer from '../components/footer';
import jwt_decode from 'jwt-decode';
import { fetchfeedbackOnProgram, fetchfeedbackOnCourses } from '../services/api';
import { Navigate } from 'react-router-dom';


const Feedback = () => {
    document.title = "Feedback";
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const view_feedback_status = decodedToken.permissions.view_feedback=== '0'? false : true;
    const [feedbackOnProgram, setFeedbackOnProgram] = useState([]);
    const [feedbackOnCourses, setFeedbackOnCourses] = useState([]);


    useEffect(() => {
        fetchAllFeedbackOnProgram();
        fetchAllFeedbackOnCourses();
    }, []);

    const fetchAllFeedbackOnProgram = async () => {
        fetchfeedbackOnProgram()
            .then((data) => {
                setFeedbackOnProgram(data);
            })
            .catch((error) => console.error('Error fetching feedback on program: ', error));
    }

    const fetchAllFeedbackOnCourses = async () => {
        fetchfeedbackOnCourses()
            .then((data) => {
                setFeedbackOnCourses(data);
            })
            .catch((error) => console.error('Error fetching feedback on courses: ', error));
    }

    if ( role === 'qa' && view_feedback_status) {
        return (
        <>
            <HeaderLoggedIn role={role} />
            <div className="content-containerFeedback">
                <div className="section-feedback">
                    <h2>Feedback on Program</h2>
                    <div className="reports feedback">

                        <table>
                            <thead>
                                <tr>
                                    <th>Program Name</th>
                                    <th>Program Feedback</th>
                                </tr>
                            </thead>
                            <tbody>
                                {feedbackOnProgram.map((feedback) => (
                                    <tr key={feedback.Row_Number}>
                                        <td>{feedback.Program_Name}</td>
                                        <td>{feedback.Feedback}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="section-feedback">
                    <h2>Feedback on Courses</h2>
                    <div className="reports feedback">

                        <table>
                            <thead>
                                <tr>
                                    <th>Courses Name</th>
                                    <th>Courses Feedback</th>
                                </tr>
                            </thead>
                            <tbody>
                                {feedbackOnCourses.map((analysis) => (
                                    <tr key={analysis.Row_Number}>
                                        <td>{analysis.Course_Name}</td>
                                        <td>{analysis.Student_Course_Feedback}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
                
            </div>
            <Footer />
        </>
    );
}
else {
  return <Navigate to="/" replace={true} />
};
};

export default Feedback;
