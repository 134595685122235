import React, { useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import '../../css/auth.css';
import { authenticateUser, userLogin } from '../../services/api';
import jwt_decode from 'jwt-decode';

const Login = () => {
  document.title = "Login";
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [formError, setFormError] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  }

  const validatePassword = (password) => {
    return password.length >= 6; // Example: Require at least 6 characters
  }

  const handleAuthentication = () => {
    setFormError(''); // Reset error when user tries to authenticate

    if (!validateEmail(email) || !validatePassword(password)) {
      setFormError('Invalid Email or Password. Try Again.');
      return;
    }

    authenticateUser({ email, password })
      .then((response) => {
        if (response.token) {
          localStorage.setItem('token', response.token);

          // Decode the JWT token to extract the role
          const decodedToken = jwt_decode(response.token);

          if (decodedToken.role) {
            switch (decodedToken.role.toLowerCase()) {
              case 'admin':
                window.location.href = '/adminDashboard';
                break;
              case 'student':
                window.location.href = '/student_dashboard';
                break;
              case 'instructor':
                window.location.href = '/instructor/dashboard';
                break;
              case 'qa':
                window.location.href = '/QADashboard';
                break;
              case 'coordinator':
                window.location.href = '/coordinator_dashboard';
                break;
              default:
                window.alert("Role not found");
            }
          } else {
            window.alert("Role not found in the token.");
          }
        } else {
          window.alert("Invalid email or password.");
        }
      })
      .catch(error => {
        console.error('Error sending message:', error);
      });
  };
  

  return (
    <>
      <Header />

      <div className="main-content">
        <div className="container_shrd">
          <h2 className='c_h2'>Sign In</h2>
          <form id="login-form" className="form active">
            <input type="text" placeholder="Email ID" required value={email} onChange={e => setEmail(e.target.value)} />
            <input type="password" placeholder="Password" required value={password} onChange={e => setPassword(e.target.value)} />
            {formError && <p className="error">{formError}</p>}
            <button type="button" onClick={handleAuthentication}>Sign In</button>
            <button type="button" className="forgot-password">
              <a href="./forgotPassword" style={{ color: '#FFF' }}>Forgot Password?</a>
            </button>
          </form>
        </div>
        {/* Put the footer at absolute bottom */}
        <div className="footer-push">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Login;
