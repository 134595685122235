import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import '../css/style.css';
import '../css/header.css';
import {fetchCourses, fetchEnrolledCourses, addStudent, removeStudent} from '../services/api';
import HeaderLoggedIn from '../components/headerLoggedIn';
import Footer from '../components/footer';
import jwt_decode from 'jwt-decode';

function ManageStudents() {
  document.title = "Student Management";
  const decodedToken = jwt_decode(localStorage.getItem('token'));
  const role = decodedToken.role.toLowerCase();
  const student_manage_status = decodedToken.permissions.student_manage=== '0'? false : true;
  const [availableCourses, setAvailableCourses] = useState([]); // State for available courses
  const [studentId, setStudentId] = useState(''); // State for student ID
  const [studentId1, setStudentId1] = useState(''); // State for student ID
  const [enrolledCourses, setEnrolledCourses] = useState([]); // State for enrolled courses
  const [selectedCourse, setSelectedCourse] = useState(''); // State for the selected course

  useEffect(() => {
    // Fetch available courses when the component mounts
    fetchCourses()
      .then((data) => setAvailableCourses(data))
      .catch((error) => console.error(error));
  }, []);

  // Create a function to fetch enrolled courses based on the student ID
  const fetch_Courses = () => {
    // Implement the logic to fetch the enrolled courses for the student based on studentId
    // Update the enrolledCourses state with the fetched data
    fetchEnrolledCourses(studentId1)
      .then((data) => {
      // const courseNames = data.map((course) => course.Course_Name);
      setEnrolledCourses(data);
      })
      .catch((error) => alert(error));
  };

  // Function to add a student to a course
  const handleRemoveStudent = (e) => {
    e.preventDefault();
    
    const x = document.getElementById('studentId1').value;
    const y = document.getElementById('course1').value;
    if (!x || !y) {
      alert('Please enter both student ID and course ID.');
      return;
    }
      // Send a request to the API endpoint for adding a student to a course
      removeStudent({x , y})
      .then((response) => {
        // Handle the response, e.g., display a success message or handle errors
        alert(response);
        
        // Clear the input fields or perform any other necessary actions
        setStudentId1('');
      })
    .catch((error) => alert(error));
  };

  // Function to remove a student from a course
  const handleAddStudent = (e) => {
    e.preventDefault();
    
    const x = document.getElementById('studentId').value;
    if (!x || !selectedCourse) {
      alert('Please enter both student ID and course ID.');
      return;
    }
    fetchEnrolledCourses(x)
      .then((data) => {
      const courseNames = data.map((course) => course.Course_Name);
      if(courseNames.includes(selectedCourse))
      {
        alert("Student already enrolled in this course");
        return;
      }

      // Send a request to the API endpoint for adding a student to a course
      addStudent({x , selectedCourse})
      .then((response) => {
        // Handle the response, e.g., display a success message or handle errors
        alert(response);
        
        // Clear the input fields or perform any other necessary actions
        setStudentId('');
      })
    .catch((error) => alert(error));
      })
      .catch((error) => alert(error));
  };

  if ((role === 'coordinator' && student_manage_status) || role === 'admin') {
    return (
    <>
    <HeaderLoggedIn role ={role}/>
    <div>
      <div className="hk">
        <section className="hero">
          <h1>Manage Students</h1>
        </section>

        <div className="c_container">
          <h2>Add Student to Course</h2>
          <form action="add_student.php" method="post" className="create-course-form" onSubmit={handleAddStudent}>
            <label htmlFor="studentId">Student ID:</label>
            <input 
            type="text"
            id="studentId" 
            name="studentId" 
            required 
            onChange={(e) => {
              setStudentId(e.target.value); 
              document.getElementById('course').value='';
            }}
            />
            <br /><br />

            <label htmlFor="course">Select Course:</label>
            <select
                  id="course"
                  name="course"
                  defaultValue=""
                  onChange={(e) => setSelectedCourse(e.target.value)}
                >
                  <option value="" disabled hidden>
                    Select course
                  </option>
                  {availableCourses.map((course) => (
                    <option key={course.Course_ID} value={course.Course_Name}>
                      {course.Course_Name}
                    </option>
                  ))}
                </select>
                <br /><br />

            <input type="submit" value="Add Student to Course" />
          </form>
        </div>
        <br /><br />
        <div className="c_container">
          <h2>Remove Student from Course</h2>
          <form action="remove_student.php" method="post" className="create-course-form" onSubmit={handleRemoveStudent}>
                <label htmlFor="studentID">Student ID:</label>
                <input
                  type="text"
                  id="studentId1"
                  name="studentId1"
                  required
                  defaultValue="" 
                  onChange={(e) => {
                    setStudentId1(e.target.value)
                    document.getElementById('course1').value='';
                  }}
                />
                <br />
                <br />

                <label htmlFor="course">Select Course:</label>
                <select id="course1" name="course1" defaultValue="" onClick={fetch_Courses}>
                  <option value="" disabled hidden>
                    Select course
                  </option>
                  {enrolledCourses.map((course) => (
                    <option key={course.Course_ID} value={course.Course_ID}>
                      {course.Course_Name}
                    </option>
                  ))}
                </select>
                <br />
                <br />

            <input type="submit" value="Remove Student from Course" />
          </form>
        </div>
        <br /><br />
      </div>
    </div>
    <Footer/>
    </>
  );
  }
  else {
    return <Navigate to="/" replace={true} />
  };
}

export default ManageStudents;
