
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Header from './components/header';
import HeaderLoggedIn from './components/headerLoggedIn';
import Footer from './components/footer';
import Services from './pages/services';
import About from './pages/about';
import Chat from './pages/chat/chat';
import DiscussionForum from './pages/discussionForum/discussionForum';
import Home from './pages/home';
import QADashboard from './pages/QAOfficer/QADashboard';
import QAPolicies from './pages/QAOfficer/processesAndPolicies';
import ContactUs from './pages/ContactUs';
import Courses from './pages/Courses';
import ReportsAndAnalytics from './pages/reports_analytics';
import AttendanceAnalytics from './pages/attendance_analytics';
import StudentReports from './pages/student_reports';
import ProgramCoordinatorHomepage from './pages/coordinator_dashboard';
import ManageCourses from './pages/course_manage';
import ManageStudents from './pages/student_manage';
import ManageInstructors from './pages/instructor_manage';
import InstructorDashboard from './pages/instructor/instructorDashboard';
import InstructorProfile from './pages/instructor/instructorProfile';
import CourseHome from './pages/instructor/courseHome';
import Announcements from './pages/instructor/announcements';
import Syllabus from './pages/instructor/syllabus';
import Modules from './pages/instructor/modules';
import Exams from './pages/instructor/exams';
import Grades from './pages/instructor/grades';
import People from './pages/instructor/people';
import Reports from './pages/instructor/reports';
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import ForgotPassword from './pages/auth/forgotPassword';
import Feedback from './pages/feedback';
import StudentDashboard from './pages/student_dashboard';
import StudentProfile from './pages/student_profile';
import ProgramObjectives from './pages/program-objectives';
import AdminDashboard from './pages/admin/adminDashboard';
import { validateToken } from './utils/authUtils';

function ProtectedRoute({ element: Element, ...rest }) {
  return (
    validateToken() ? <Element {...rest} /> : <Navigate to="/auth/login" replace={true} />
  );
}

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/about' element={<About />} />
          <Route path='/courses' element={<Courses />} />
          <Route path='/auth/login' element={<Login />} />
          <Route path='/auth/register' element={<Register />} />
          <Route path='/auth/forgotPassword' element={<ForgotPassword />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/program-objectives' element={< ProgramObjectives />} />
          <Route path='*' element={<Navigate to="/" replace={true} />} />
          {/* Protected routes */}
          <Route path='/chat' element={<ProtectedRoute element={Chat} />} />
          <Route path='/discussionForum' element={<ProtectedRoute element={DiscussionForum} />} />
          <Route path='/QADashboard' element={<ProtectedRoute element={QADashboard} />} />
          <Route path='/QAPolicies' element={<ProtectedRoute element={QAPolicies} />} />
          <Route path='/reporting_analytics' element={<ProtectedRoute element={ReportsAndAnalytics} />} />
          <Route path='/attendance_analytics' element={<ProtectedRoute element={AttendanceAnalytics} />} />
          <Route path='/student_reports' element={<ProtectedRoute element={StudentReports} />} />
          <Route path='/coordinator_dashboard' element={<ProtectedRoute element={ProgramCoordinatorHomepage} />} />
          <Route path='/course_manage' element={<ProtectedRoute element={ManageCourses} />} />
          <Route path='/student_manage' element={<ProtectedRoute element={ManageStudents} />} />
          <Route path='/instructor_manage' element={<ProtectedRoute element={ManageInstructors} />} />
          <Route path='/instructor/dashboard' element={<ProtectedRoute element={InstructorDashboard} />} />
          <Route path='/instructor/profile' element={<ProtectedRoute element={InstructorProfile} />} />
          <Route path='/instructor/coursehome/:course_id' element={<ProtectedRoute element={CourseHome} />} />
          <Route path='/instructor/announcments/:course_id' element={<ProtectedRoute element={Announcements} />} />
          <Route path='/instructor/syllabus/:course_id' element={<ProtectedRoute element={Syllabus} />} />
          <Route path='/instructor/modules/:course_id' element={<ProtectedRoute element={Modules} />} />
          <Route path='/instructor/exams/:course_id' element={<ProtectedRoute element={Exams} />} />
          <Route path='/instructor/grades/:course_id' element={<ProtectedRoute element={Grades} />} />
          <Route path='/instructor/people/:course_id' element={<ProtectedRoute element={People} />} />
          <Route path='/instructor/reports/:course_id' element={<ProtectedRoute element={Reports} />} />

          <Route path='/student_dashboard' element={<ProtectedRoute element={StudentDashboard} />} />
          <Route path='/student_profile' element={<ProtectedRoute element={StudentProfile} />} />
          <Route path='/feedback' element={<ProtectedRoute element={Feedback} />} />
          <Route path='/adminDashboard' element={<ProtectedRoute element={AdminDashboard} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
