import React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import HeaderLoggedIn from '../../components/headerLoggedIn';
import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faGraduationCap, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import './people.css';
import { getCourseParticipants } from '../../services/api';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const People = () => {
    document.title = "People";

    const [people, setPeople] = useState([]);
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    const role = decodedToken.role.toLowerCase();
    const { course_id } = useParams();


    useEffect(() => {
        // Fetch people data when the component is mounted
        const fetchPeople = async () => {
            try {
                const data = await getCourseParticipants(course_id);
                setPeople(data); // Set the people data to state
            } catch (error) {
                console.error('Error fetching people data:', error);
            }
        };

        fetchPeople();
    }, [course_id]);

    // Function to determine icon based on role
    const getIcon = (role) => {
        switch (role) {
            case 'Instructor':
                return faChalkboardTeacher;
            case 'Student':
                return faUserGraduate;
            default:
                return null; // No icon for other roles
        }
    };

    if (role === 'instructor') {
        return (
            <>
                <HeaderLoggedIn role={role} />
                <div className="peopleContainer">
                    <div className="people-main-content">
                        <div className="people-nav">
                            <Link to={`/instructor/coursehome/${course_id}`} >Home</Link>
                            <Link to={`/instructor/announcments/${course_id}`} >Announcements</Link>
                            <Link to={`/instructor/syllabus/${course_id}`}>Syllabus</Link>
                            <Link to={`/instructor/modules/${course_id}`} >Modules</Link>
                            <Link to={`/instructor/exams/${course_id}`} >Exams</Link>
                            <Link to={`/instructor/grades/${course_id}`}>Grades</Link>
                            <Link to={`/instructor/people/${course_id}`} className="people-selected">People</Link>
                            <Link to={`/instructor/reports/${course_id}`}>Reports</Link>
                        </div>
                        <main className="people-content" aria-label="People">
                            {people.map((person, idx) => (
                                <div className="person" key={idx}>
                                    <FontAwesomeIcon icon={getIcon(person.role)} className="icon" />
                                    <div>
                                        <div>{person.name}</div>
                                        <small>{person.role}</small>
                                    </div>
                                </div>
                            ))}
                        </main>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    else {
        return <Navigate to="/" replace={true} />
    };
};

export default People;
