import React from 'react';
import '../css/contact.css';
import '../css/header.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { validateToken } from '../utils/authUtils';
import { handleContact } from '../services/api';
import HeaderLoggedIn from '../components/headerLoggedIn';
import jwt_decode from 'jwt-decode';

function ContactUs() {
  document.title = "Contact Us";
  const token = localStorage.getItem('token');
    var role = '';
    if (token) {
        role = jwt_decode(token).role.toLowerCase();
    }

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");

     if (!validateEmail(email)) 
    {
      alert("Please enter a valid email address.");
      return;
    }

    handleContact({name, email, message})
      .then((response) => {
        alert(response);
      })
      .catch((error) => console.log(error));
  }
  return (
    <>
     {validateToken() ? <HeaderLoggedIn role={role}/> : <Header />}
    <div className="hk">
      <main style={{ padding: '20px' }}>
        <section>
          <h1 style={{ fontSize: '30px', textAlign: 'left' , paddingLeft: '40px' }}>Contact Us:</h1>
          <p style={{ fontSize: '20px', marginBottom: '20px' }}>
            Get in touch with us if you have any questions, feedback, or need assistance. We're here to help you!
          </p>
          <br /><br />
          <div className="contact-form">
            <form onSubmit={handleSubmit} method="post">
              <label htmlFor="name">Your Name:</label>
              <input type="text" id="name" name="name" required />

              <label htmlFor="email">Your Email:</label>
              <input type="email" id="email" name="email" required />

              <label htmlFor="message">Message:</label>
              <textarea id="message" rows="4" name="message" required></textarea>

              <button className="contact-button" type="submit">Send Message</button>
            </form>
          </div>
        </section>
      </main>
      <br /><br />
    </div>
    <Footer/>
    </>
  );
}

export default ContactUs;
